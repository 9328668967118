import { useQuery } from 'urql';
import { CurrentUserFavouritesCountDocument } from './useCurrentUserFavouritesCount.generated';
import { useUserFavouritesCount } from './useUserFavouritesCount';
import { ExamType } from './types/ExamType';
import { useStoreState } from '~/store/hooks';
import { ExamEventType } from '~/types/graphql';

interface CurrentUserHasFavouritesProps {
    filter?: {
        examType?: ExamType;
    };
}

type CurrentUserFavouritesCount = number;
type FavouritesFetching = boolean;

/**
 * React Hook to check for existence of favourites for the current user.
 * @returns Tuble with two values:
 */
export function useCurrentUserFavouritesCount({
    filter,
}: CurrentUserHasFavouritesProps = {}): [
    CurrentUserFavouritesCount,
    FavouritesFetching
] {
    const userMode = useStoreState((state) => state.userMode.mode);
    const isLongRunning =
        filter?.examType !== undefined
            ? filter.examType === ExamEventType.Practice
            : null;

    const [result] = useQuery({
        query: CurrentUserFavouritesCountDocument,
        variables: {
            isLongRunning,
        },
        requestPolicy: 'cache-first',
    });

    const fetching = result?.fetching || result?.stale;

    const currentUser = result?.data?.currentUser;
    const currentUserFavouritesCount = useUserFavouritesCount({
        user: currentUser,
        filter: {
            examType: filter?.examType,
            exclude: userMode === 'author' ? ['passes'] : null,
            include: userMode === 'student' ? ['passes'] : null,
        },
    });

    return [currentUserFavouritesCount, fetching];
}
