export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar type represents time data. */
  DateTime: { input: Date; output: Date; }
  /** The `Iterable` scalar type represents an array or a Traversable with any kind of data. */
  Iterable: { input: any; output: any; }
  /** The `Ulid` scalar type represents a Ulid UUID. */
  Ulid: { input: any; output: any; }
  /** The `Upload` type represents a file to be uploaded in the same HTTP request as specified by [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec). */
  Upload: { input: any; output: any; }
};

export type AnomalyLog = ExamPassLog & Node & {
  __typename: 'AnomalyLog';
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

export type Answer = {
  _id: Scalars['Int']['output'];
  block: Block;
  correction?: Maybe<Correction>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type AnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Assignment = Node & {
  __typename: 'Assignment';
  _id: Scalars['Int']['output'];
  blocks?: Maybe<BlockConnection>;
  children?: Maybe<Array<Maybe<Assignment>>>;
  exam: Exam;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Assignment>;
  position: Scalars['Int']['output'];
  taskBlocks?: Maybe<Array<Maybe<TaskBlock>>>;
  /** We need to keep this for api-platform otherwise errors like this occur: https://whatwedo.sentry.io/issues/5757831904/?project=6004515 */
  title: Scalars['String']['output'];
};


export type AssignmentBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AssignmentTaskBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AuthLog = ExamPassLog & Node & {
  __typename: 'AuthLog';
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

export type Block = {
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<Answer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

/** Cursor connection for Block. */
export type BlockConnection = {
  __typename: 'BlockConnection';
  edges?: Maybe<Array<Maybe<BlockEdge>>>;
  pageInfo: BlockPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BlockDisplayPosition = Node & {
  __typename: 'BlockDisplayPosition';
  _id: Scalars['Int']['output'];
  block: Block;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  user: User;
};

/** Edge of Block. */
export type BlockEdge = {
  __typename: 'BlockEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Block>;
};

export type BlockLog = ExamPassLog & Node & {
  __typename: 'BlockLog';
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  block?: Maybe<Block>;
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

/** Information about the current page. */
export type BlockPageInfo = {
  __typename: 'BlockPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type CodeAreaAnswer = Answer & Node & {
  __typename: 'CodeAreaAnswer';
  _id: Scalars['Int']['output'];
  block: CodeAreaBlock;
  content?: Maybe<Scalars['String']['output']>;
  correction?: Maybe<Correction>;
  correctionContent?: Maybe<Scalars['String']['output']>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type CodeAreaAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CodeAreaBlock = Block & Node & {
  __typename: 'CodeAreaBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<CodeAreaAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type CodeBlock = Block & Node & {
  __typename: 'CodeBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  content?: Maybe<Scalars['String']['output']>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<Answer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type Content = Node & {
  __typename: 'Content';
  _id: Scalars['Int']['output'];
  disabledFeatures?: Maybe<Scalars['Int']['output']>;
  filteredParameters?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parameters?: Maybe<Scalars['String']['output']>;
};

export type ControlTaskOptions = Node & {
  __typename: 'ControlTaskOptions';
  _id: Scalars['Int']['output'];
  autoCorrect: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  showSolution?: Maybe<Scalars['Boolean']['output']>;
};

export type Correction = Node & {
  __typename: 'Correction';
  _id: Scalars['Int']['output'];
  answer: Answer;
  autoCorrected?: Maybe<Scalars['Boolean']['output']>;
  correctionApprovals?: Maybe<Array<Maybe<CorrectionApproval>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locks?: Maybe<Array<Maybe<CorrectionLock>>>;
  note?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type CorrectionApproval = Node & {
  __typename: 'CorrectionApproval';
  _id: Scalars['String']['output'];
  correction: Correction;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type CorrectionLock = Node & {
  __typename: 'CorrectionLock';
  _id: Scalars['String']['output'];
  correction: Correction;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  expired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type Course = Node & {
  __typename: 'Course';
  _id: Scalars['String']['output'];
  acronym: Scalars['String']['output'];
  authors?: Maybe<UserConnection>;
  canCreateExam?: Maybe<Scalars['Boolean']['output']>;
  exams?: Maybe<ExamConnection>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  labels?: Maybe<Scalars['Iterable']['output']>;
  name: Scalars['String']['output'];
  teachers?: Maybe<UserConnection>;
};


export type CourseAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CourseExamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ExamFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamFilter_Order>>>;
  parent?: InputMaybe<Scalars['String']['input']>;
  parent_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CourseTeachersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Cursor connection for Course. */
export type CourseConnection = {
  __typename: 'CourseConnection';
  edges?: Maybe<Array<Maybe<CourseEdge>>>;
  pageInfo: CoursePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Course. */
export type CourseEdge = {
  __typename: 'CourseEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Course>;
};

/** Information about the current page. */
export type CoursePageInfo = {
  __typename: 'CoursePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type DiskTemplate = Node & {
  __typename: 'DiskTemplate';
  _id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  machine: MachineTemplate;
  size: Scalars['Int']['output'];
  sizeInBytes?: Maybe<Scalars['Int']['output']>;
  sizeInKb?: Maybe<Scalars['Int']['output']>;
  sizeInMb?: Maybe<Scalars['Int']['output']>;
};

export type DownloadRequest = Node & {
  __typename: 'DownloadRequest';
  _id: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  examEvent: ExamEvent;
  exportFile?: Maybe<MediaObject>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

/**
 * Diese Klasse repräsentiert eine virtuelle Umgebung.
 * Objekte dieser Klasse werden basierend auf ein VirtualEnvironmentTemplate generiert.
 */
export type Environment = Node & {
  __typename: 'Environment';
  _id: Scalars['String']['output'];
  correctionMode: Scalars['Boolean']['output'];
  deregistered: Scalars['Boolean']['output'];
  examEvent?: Maybe<ExamEvent>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  fullyCreated: Scalars['Boolean']['output'];
  hashId?: Maybe<Scalars['String']['output']>;
  hostSystem: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importInProgress: Scalars['Boolean']['output'];
  machines?: Maybe<Array<Maybe<Machine>>>;
  master: Scalars['Boolean']['output'];
  networks?: Maybe<Array<Maybe<Network>>>;
  readableState: Scalars['String']['output'];
  state: Scalars['String']['output'];
  template: EnvironmentTemplate;
  /** TODO: unique=true after migrations are done, and maybe even switch id field completely */
  ulid: Scalars['Ulid']['output'];
  vlanPrefix: Scalars['Int']['output'];
  vsphereName: Scalars['String']['output'];
};


/**
 * Diese Klasse repräsentiert eine virtuelle Umgebung.
 * Objekte dieser Klasse werden basierend auf ein VirtualEnvironmentTemplate generiert.
 */
export type EnvironmentExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Diese Klasse repräsentiert eine Virtuelleumgebungs-Vorlage
 * Einzelne VirtualEnvironment's werden basierend auf diese Klasse generiert.
 * Eine virtuelle Umgebung umfasst mehrere Vm's und Netzwerke
 * Ein VirtualEnvironmentTemplate ist einer Leistungsbeurteilung zugeordnet, ein virtualenvironment jeweils einem Prüfungsdurchgang
 */
export type EnvironmentTemplate = Node & {
  __typename: 'EnvironmentTemplate';
  _id: Scalars['String']['output'];
  exam?: Maybe<Exam>;
  id: Scalars['ID']['output'];
  instances?: Maybe<Array<Maybe<Environment>>>;
  machines?: Maybe<Array<Maybe<MachineTemplate>>>;
  mastersAddedCount: Scalars['Iterable']['output'];
  networks?: Maybe<Array<Maybe<NetworkTemplate>>>;
  primaryInstance: Environment;
};

export type EvaluateLog = ExamPassLog & Node & {
  __typename: 'EvaluateLog';
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  block?: Maybe<Block>;
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

export type Exam = Node & {
  __typename: 'Exam';
  _id: Scalars['String']['output'];
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  archivedBy?: Maybe<User>;
  assignments?: Maybe<Array<Maybe<Assignment>>>;
  assignmentsEnabled: Scalars['Boolean']['output'];
  assignmentsOrderedByPosition?: Maybe<Array<Maybe<Assignment>>>;
  authorNote?: Maybe<Scalars['String']['output']>;
  autoArchiveDate?: Maybe<Scalars['DateTime']['output']>;
  autoArchiveExtendedUntil?: Maybe<Scalars['DateTime']['output']>;
  autoArchiveWarned: Scalars['Boolean']['output'];
  canCreateCopy?: Maybe<Scalars['Boolean']['output']>;
  canCreateVariant?: Maybe<Scalars['Boolean']['output']>;
  canDuplicateVariant?: Maybe<Scalars['Boolean']['output']>;
  canPublish?: Maybe<Scalars['Boolean']['output']>;
  canRevokePublished?: Maybe<Scalars['Boolean']['output']>;
  canUpdate?: Maybe<Scalars['Boolean']['output']>;
  candidateRevertEnabled: Scalars['Boolean']['output'];
  clipboardEnabled: Scalars['Boolean']['output'];
  course: Course;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  duplicatedFrom?: Maybe<Exam>;
  environment?: Maybe<EnvironmentTemplate>;
  environmentEnabled: Scalars['Boolean']['output'];
  environmentMasterSet?: Maybe<MasterSet>;
  events?: Maybe<ExamEventConnection>;
  examEventType?: Maybe<ExamEventType>;
  export?: Maybe<Scalars['Iterable']['output']>;
  hasActiveExamPass: Scalars['Boolean']['output'];
  hashId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use examEventType property and check for "PRACTICE" instead. */
  isLongRunning?: Maybe<Scalars['Boolean']['output']>;
  mainImage?: Maybe<MediaObject>;
  name: Scalars['String']['output'];
  parent?: Maybe<Exam>;
  published: Scalars['Boolean']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  remoteConsoleEnabled: Scalars['Boolean']['output'];
  substitutes?: Maybe<UserConnection>;
  teamSize: Scalars['Int']['output'];
  timeLimit?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalAvailablePoints: Scalars['String']['output'];
  /** TODO: unique=true after migrations are done, and maybe even switch id field completely */
  ulid: Scalars['Ulid']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  usbDevicesEnabled: Scalars['Boolean']['output'];
  variants?: Maybe<ExamConnection>;
  webConsoleEnabled: Scalars['Boolean']['output'];
};


export type ExamEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  availableFrom?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableFrom>>>;
  availableUntil?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableUntil>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_email?: InputMaybe<Scalars['String']['input']>;
  createdBy_email_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_course?: InputMaybe<Scalars['String']['input']>;
  examVariant_course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Array<InputMaybe<ExamEventFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamEventFilter_Order>>>;
};


export type ExamSubstitutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ExamVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ExamFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamFilter_Order>>>;
  parent?: InputMaybe<Scalars['String']['input']>;
  parent_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ExamActionLog = ExamPassLog & Node & {
  __typename: 'ExamActionLog';
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

/** Cursor connection for Exam. */
export type ExamConnection = {
  __typename: 'ExamConnection';
  edges?: Maybe<Array<Maybe<ExamEdge>>>;
  pageInfo: ExamPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Exam. */
export type ExamEdge = {
  __typename: 'ExamEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Exam>;
};

export type ExamEvent = Node & {
  __typename: 'ExamEvent';
  _id: Scalars['String']['output'];
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  archivedBy?: Maybe<User>;
  autoArchiveDate?: Maybe<Scalars['DateTime']['output']>;
  autoArchiveExtendedUntil?: Maybe<Scalars['DateTime']['output']>;
  autoArchiveWarned: Scalars['Boolean']['output'];
  availableFrom: Scalars['DateTime']['output'];
  availableUntil: Scalars['DateTime']['output'];
  correctingUsers?: Maybe<UserConnection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  environments?: Maybe<Array<Maybe<Environment>>>;
  examVariant: Exam;
  hasActiveExamPass: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  nextStageExecution?: Maybe<Scalars['DateTime']['output']>;
  passes?: Maybe<Array<Maybe<ExamPass>>>;
  requiredVirtualEnvironmentCount: Scalars['Float']['output'];
  resultDownloadLink?: Maybe<Scalars['String']['output']>;
  returnAlternativeGrade?: Maybe<Scalars['String']['output']>;
  returnGradeCalculated?: Maybe<Scalars['Boolean']['output']>;
  returnMaxPoints?: Maybe<Scalars['String']['output']>;
  returnShowCorrection?: Maybe<Scalars['Boolean']['output']>;
  returnShowReachedPoints?: Maybe<Scalars['Boolean']['output']>;
  returnShowSolution?: Maybe<Scalars['Boolean']['output']>;
  returnShowStudentSolution?: Maybe<Scalars['Boolean']['output']>;
  returned?: Maybe<Scalars['Boolean']['output']>;
  sebPassword?: Maybe<Scalars['String']['output']>;
  sebRequired: Scalars['Boolean']['output'];
  snapshotRemoved: Scalars['Boolean']['output'];
  timeLimit?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type ExamEventCorrectingUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ExamEventPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Cursor connection for ExamEvent. */
export type ExamEventConnection = {
  __typename: 'ExamEventConnection';
  edges?: Maybe<Array<Maybe<ExamEventEdge>>>;
  pageInfo: ExamEventPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ExamEvent. */
export type ExamEventEdge = {
  __typename: 'ExamEventEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ExamEvent>;
};

export type ExamEventFilter_AvailableFrom = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
  strictly_after?: InputMaybe<Scalars['DateTime']['input']>;
  strictly_before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExamEventFilter_AvailableUntil = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
  strictly_after?: InputMaybe<Scalars['DateTime']['input']>;
  strictly_before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExamEventFilter_Exists = {
  archivedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExamEventFilter_Order = {
  archivedAt?: InputMaybe<Scalars['String']['input']>;
  availableFrom?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdBy_email?: InputMaybe<Scalars['String']['input']>;
  createdBy_firstname?: InputMaybe<Scalars['String']['input']>;
  createdBy_lastname?: InputMaybe<Scalars['String']['input']>;
  createdBy_username?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the current page. */
export type ExamEventPageInfo = {
  __typename: 'ExamEventPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum ExamEventType {
  Exam = 'EXAM',
  OpenExam = 'OPEN_EXAM',
  Practice = 'PRACTICE'
}

export type ExamFilter_Exists = {
  archivedAt?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExamFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdBy_email?: InputMaybe<Scalars['String']['input']>;
  createdBy_firstname?: InputMaybe<Scalars['String']['input']>;
  createdBy_lastname?: InputMaybe<Scalars['String']['input']>;
  createdBy_username?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the current page. */
export type ExamPageInfo = {
  __typename: 'ExamPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ExamPass = Node & {
  __typename: 'ExamPass';
  _id: Scalars['Int']['output'];
  absent: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  answers?: Maybe<Array<Maybe<Answer>>>;
  bookmark?: Maybe<Assignment>;
  canPause?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  environment?: Maybe<Environment>;
  event: ExamEvent;
  hasActiveExamPass: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interrupted: Scalars['Boolean']['output'];
  interruptedAt?: Maybe<Scalars['DateTime']['output']>;
  interruptedTime: Scalars['Int']['output'];
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  locked: Scalars['Boolean']['output'];
  minutesSpent?: Maybe<Scalars['Int']['output']>;
  missingRequiredBlocks?: Maybe<Array<Maybe<MissingRequiredBlock>>>;
  moodleAssignmentId?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  paused: Scalars['Boolean']['output'];
  pausedAt?: Maybe<Scalars['DateTime']['output']>;
  pausedTime: Scalars['Int']['output'];
  progress?: Maybe<Scalars['Int']['output']>;
  returnShowCorrection?: Maybe<Scalars['Boolean']['output']>;
  returnShowReachedPoints?: Maybe<Scalars['Boolean']['output']>;
  returnShowSolution?: Maybe<Scalars['Boolean']['output']>;
  returnShowStudentSolution?: Maybe<Scalars['Boolean']['output']>;
  returned?: Maybe<Scalars['Boolean']['output']>;
  sebConfigKey?: Maybe<Scalars['String']['output']>;
  sebLink: Scalars['String']['output'];
  sebRequired?: Maybe<Scalars['Boolean']['output']>;
  seed: Scalars['Int']['output'];
  startTime?: Maybe<Scalars['DateTime']['output']>;
  teamId?: Maybe<Scalars['Int']['output']>;
  teamIndex?: Maybe<Scalars['Int']['output']>;
  timeLimit?: Maybe<Scalars['Int']['output']>;
  totalPoints: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};


export type ExamPassAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ExamPassLog = {
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

/** Cursor connection for ExamPassLog. */
export type ExamPassLogConnection = {
  __typename: 'ExamPassLogConnection';
  edges?: Maybe<Array<Maybe<ExamPassLogEdge>>>;
  pageInfo: ExamPassLogPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ExamPassLog. */
export type ExamPassLogEdge = {
  __typename: 'ExamPassLogEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ExamPassLog>;
};

/** Information about the current page. */
export type ExamPassLogPageInfo = {
  __typename: 'ExamPassLogPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ExamPingLog = ExamPassLog & Node & {
  __typename: 'ExamPingLog';
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

export type FileTransferAnswer = Answer & Node & {
  __typename: 'FileTransferAnswer';
  _id: Scalars['Int']['output'];
  block: FileTransferBlock;
  correction?: Maybe<Correction>;
  correctionFiles?: Maybe<Array<Maybe<MediaObject>>>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  files?: Maybe<Array<Maybe<MediaObject>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  missingAnswer: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};


export type FileTransferAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FileTransferBlock = Block & Node & {
  __typename: 'FileTransferBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  directoryPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  machine?: Maybe<MachineTemplate>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<FileTransferAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type FileUploadAnswer = Answer & Node & {
  __typename: 'FileUploadAnswer';
  _id: Scalars['Int']['output'];
  block: FileUploadBlock;
  correction?: Maybe<Correction>;
  correctionFiles?: Maybe<Array<Maybe<MediaObject>>>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  files?: Maybe<Array<Maybe<MediaObject>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  missingAnswer: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};


export type FileUploadAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FileUploadBlock = Block & Node & {
  __typename: 'FileUploadBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  numberOfFiles?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<FileUploadAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type FilesBlock = Block & Node & {
  __typename: 'FilesBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  files?: Maybe<Array<Maybe<MediaObject>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<Answer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type Group = Node & {
  __typename: 'Group';
  _id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['String']['output']>;
  importSource?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  users?: Maybe<UserConnection>;
};


export type GroupUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Cursor connection for Group. */
export type GroupConnection = {
  __typename: 'GroupConnection';
  edges?: Maybe<Array<Maybe<GroupEdge>>>;
  pageInfo: GroupPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Group. */
export type GroupEdge = {
  __typename: 'GroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Group>;
};

export type GroupFilter_Exists = {
  users?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Information about the current page. */
export type GroupPageInfo = {
  __typename: 'GroupPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type H5pBlock = Block & Node & {
  __typename: 'H5pBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  contents?: Maybe<Array<Maybe<Content>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  h5pContents?: Maybe<Array<Maybe<Scalars['Iterable']['output']>>>;
  h5p_contents: Scalars['Iterable']['output'];
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<Answer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};


export type H5pBlockH5pContentsArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Diese Klasse repräsentiert eine virtuelle Maschine.
 * Objekte dieser Klasse werden basierend auf einem VmTemplate generiert.
 */
export type Machine = Node & {
  __typename: 'Machine';
  _id: Scalars['String']['output'];
  canConsole: Scalars['Boolean']['output'];
  datastore?: Maybe<Scalars['String']['output']>;
  environment: Environment;
  id: Scalars['ID']['output'];
  interfaces?: Maybe<Array<Maybe<NetworkInterface>>>;
  rdpLink?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  template: MachineTemplate;
  tenantTransfers?: Maybe<Array<Maybe<TenantTransfer>>>;
  transferNetworkIpAddress?: Maybe<Scalars['String']['output']>;
  vmrcLink: Scalars['String']['output'];
  vmxPath?: Maybe<Scalars['String']['output']>;
  vsphereName: Scalars['String']['output'];
  webmksLink?: Maybe<Scalars['String']['output']>;
};

/**
 * Diese Klasse repräsentiert ein VM-Image.
 * Ein VM-Image wird später bei der generierung von VM's als Basis benutzt.
 */
export type MachineMaster = Node & {
  __typename: 'MachineMaster';
  _id: Scalars['String']['output'];
  cdDevice?: Maybe<Scalars['String']['output']>;
  configurable: Scalars['Boolean']['output'];
  connection: Scalars['String']['output'];
  cpu: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  fileTransferPath?: Maybe<Scalars['String']['output']>;
  floppyDevice?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interfaces?: Maybe<Array<Maybe<NetworkInterfaceMaster>>>;
  isPreconfigured: Scalars['Boolean']['output'];
  masterSet: MasterSet;
  name: Scalars['String']['output'];
  osFamily: Scalars['String']['output'];
  password: Scalars['String']['output'];
  primaryInterface?: Maybe<NetworkInterfaceMaster>;
  ram: Scalars['Int']['output'];
  snapshotId: Scalars['String']['output'];
  snapshotName?: Maybe<Scalars['String']['output']>;
  templates?: Maybe<Array<Maybe<MachineTemplate>>>;
  uploadPath?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  vmName: Scalars['String']['output'];
};

export type MachinePermission = Node & {
  __typename: 'MachinePermission';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  machine: MachineTemplate;
  participantIndex: Scalars['Int']['output'];
  permission: Scalars['Int']['output'];
};

/**
 * Diese Klasse repräsentiert eine Vm-Vorlage.
 * Einzelne VM's werden basierend auf diese Klasse generiert.
 */
export type MachineTemplate = Node & {
  __typename: 'MachineTemplate';
  _id: Scalars['String']['output'];
  autoBoot: Scalars['Boolean']['output'];
  cpu: Scalars['Int']['output'];
  disks?: Maybe<Array<Maybe<DiskTemplate>>>;
  environment: EnvironmentTemplate;
  id: Scalars['ID']['output'];
  instances?: Maybe<Array<Maybe<Machine>>>;
  interfaces?: Maybe<Array<Maybe<NetworkInterfaceTemplate>>>;
  master: MachineMaster;
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Maybe<MachinePermission>>>;
  primaryInstance?: Maybe<Machine>;
  primaryInterface?: Maybe<NetworkInterfaceTemplate>;
  primaryNetworkName?: Maybe<Scalars['String']['output']>;
  ram: Scalars['Int']['output'];
};


/**
 * Diese Klasse repräsentiert eine Vm-Vorlage.
 * Einzelne VM's werden basierend auf diese Klasse generiert.
 */
export type MachineTemplatePermissionsArgs = {
  machine?: InputMaybe<Scalars['String']['input']>;
  machine_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MasterSet = Node & {
  __typename: 'MasterSet';
  _id: Scalars['Int']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  machines?: Maybe<Array<Maybe<MachineMaster>>>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  singleMachine: Scalars['Boolean']['output'];
  vspherePath: Scalars['String']['output'];
};

export type MatrixChoiceAnswer = Answer & Node & {
  __typename: 'MatrixChoiceAnswer';
  _id: Scalars['Int']['output'];
  answers?: Maybe<Scalars['Iterable']['output']>;
  block: MatrixChoiceBlock;
  correction?: Maybe<Correction>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type MatrixChoiceAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MatrixChoiceBlock = Block & Node & {
  __typename: 'MatrixChoiceBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  answers?: Maybe<Scalars['Iterable']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isAutoCorrectable?: Maybe<Scalars['Boolean']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  questions?: Maybe<Scalars['Iterable']['output']>;
  randomContent?: Maybe<Scalars['Boolean']['output']>;
  randomContentAnswers?: Maybe<Scalars['Boolean']['output']>;
  randomizedContentProperties: Scalars['Iterable']['output'];
  solution?: Maybe<MatrixChoiceAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type MediaObject = Node & {
  __typename: 'MediaObject';
  contentUrl: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dimensions?: Maybe<Scalars['Iterable']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  filesBlock?: Maybe<FilesBlock>;
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  size: Scalars['Int']['output'];
};

export type MissingRequiredBlock = Node & {
  __typename: 'MissingRequiredBlock';
  _id: Scalars['Int']['output'];
  assignmentTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  taskBlocksTitles: Scalars['Iterable']['output'];
};

export type MultipleChoiceAnswer = Answer & Node & {
  __typename: 'MultipleChoiceAnswer';
  _id: Scalars['Int']['output'];
  block: MultipleChoiceBlock;
  choices?: Maybe<Scalars['Iterable']['output']>;
  correction?: Maybe<Correction>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type MultipleChoiceAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MultipleChoiceBlock = Block & Node & {
  __typename: 'MultipleChoiceBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  choices?: Maybe<Scalars['Iterable']['output']>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isAutoCorrectable?: Maybe<Scalars['Boolean']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  randomContent?: Maybe<Scalars['Boolean']['output']>;
  randomizedContentProperties: Scalars['Iterable']['output'];
  solution?: Maybe<MultipleChoiceAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  /** AddAllMachinesTos a EnvironmentTemplate. */
  addAllMachinesToEnvironmentTemplate?: Maybe<AddAllMachinesToEnvironmentTemplatePayload>;
  /** AddGroupsTos a ExamEvent. */
  addGroupsToExamEvent?: Maybe<AddGroupsToExamEventPayload>;
  /** AddMachineTos a EnvironmentTemplate. */
  addMachineToEnvironmentTemplate?: Maybe<AddMachineToEnvironmentTemplatePayload>;
  /** AddUsersTos a ExamEvent. */
  addUsersToExamEvent?: Maybe<AddUsersToExamEventPayload>;
  /** Archives a Exam. */
  archiveExam?: Maybe<ArchiveExamPayload>;
  /** Archives a ExamEvent. */
  archiveExamEvent?: Maybe<ArchiveExamEventPayload>;
  /** Begins a ExamPass. */
  beginExamPass?: Maybe<BeginExamPassPayload>;
  /** Captures a ScreenshotAnswer. */
  captureScreenshotAnswer?: Maybe<CaptureScreenshotAnswerPayload>;
  /** CopyPastes a Assignment. */
  copyPasteAssignment?: Maybe<CopyPasteAssignmentPayload>;
  /** Creates a AnomalyLog. */
  createAnomalyLog?: Maybe<CreateAnomalyLogPayload>;
  /** Creates a Assignment. */
  createAssignment?: Maybe<CreateAssignmentPayload>;
  /** Creates a AuthLog. */
  createAuthLog?: Maybe<CreateAuthLogPayload>;
  /** Creates a BlockDisplayPosition. */
  createBlockDisplayPosition?: Maybe<CreateBlockDisplayPositionPayload>;
  /** Creates a BlockLog. */
  createBlockLog?: Maybe<CreateBlockLogPayload>;
  /** Creates a CodeAreaAnswer. */
  createCodeAreaAnswer?: Maybe<CreateCodeAreaAnswerPayload>;
  /** Creates a CodeAreaBlock. */
  createCodeAreaBlock?: Maybe<CreateCodeAreaBlockPayload>;
  /** Creates a CodeBlock. */
  createCodeBlock?: Maybe<CreateCodeBlockPayload>;
  /** Creates a Content. */
  createContent?: Maybe<CreateContentPayload>;
  /** Creates a ControlTaskOptions. */
  createControlTaskOptions?: Maybe<CreateControlTaskOptionsPayload>;
  /** CreateCopys a Exam. */
  createCopyExam?: Maybe<CreateCopyExamPayload>;
  /** Creates a Correction. */
  createCorrection?: Maybe<CreateCorrectionPayload>;
  /** Creates a CorrectionApproval. */
  createCorrectionApproval?: Maybe<CreateCorrectionApprovalPayload>;
  /** Creates a CorrectionLock. */
  createCorrectionLock?: Maybe<CreateCorrectionLockPayload>;
  /** Creates a DiskTemplate. */
  createDiskTemplate?: Maybe<CreateDiskTemplatePayload>;
  /** Creates a DownloadRequest. */
  createDownloadRequest?: Maybe<CreateDownloadRequestPayload>;
  /** Creates a EvaluateLog. */
  createEvaluateLog?: Maybe<CreateEvaluateLogPayload>;
  /** Creates a Exam. */
  createExam?: Maybe<CreateExamPayload>;
  /** Creates a ExamActionLog. */
  createExamActionLog?: Maybe<CreateExamActionLogPayload>;
  /** Creates a ExamEvent. */
  createExamEvent?: Maybe<CreateExamEventPayload>;
  /** Creates a ExamPingLog. */
  createExamPingLog?: Maybe<CreateExamPingLogPayload>;
  /** Creates a FileTransferBlock. */
  createFileTransferBlock?: Maybe<CreateFileTransferBlockPayload>;
  /** Creates a FileUploadAnswer. */
  createFileUploadAnswer?: Maybe<CreateFileUploadAnswerPayload>;
  /** Creates a FileUploadBlock. */
  createFileUploadBlock?: Maybe<CreateFileUploadBlockPayload>;
  /** Creates a FilesBlock. */
  createFilesBlock?: Maybe<CreateFilesBlockPayload>;
  /** Creates a H5pBlock. */
  createH5pBlock?: Maybe<CreateH5pBlockPayload>;
  /** Creates a MatrixChoiceAnswer. */
  createMatrixChoiceAnswer?: Maybe<CreateMatrixChoiceAnswerPayload>;
  /** Creates a MatrixChoiceBlock. */
  createMatrixChoiceBlock?: Maybe<CreateMatrixChoiceBlockPayload>;
  /** Creates a MultipleChoiceAnswer. */
  createMultipleChoiceAnswer?: Maybe<CreateMultipleChoiceAnswerPayload>;
  /** Creates a MultipleChoiceBlock. */
  createMultipleChoiceBlock?: Maybe<CreateMultipleChoiceBlockPayload>;
  /** Creates a RichTextBlock. */
  createRichTextBlock?: Maybe<CreateRichTextBlockPayload>;
  /** Creates a RightWrongAssertionAnswer. */
  createRightWrongAssertionAnswer?: Maybe<CreateRightWrongAssertionAnswerPayload>;
  /** Creates a RightWrongAssertionBlock. */
  createRightWrongAssertionBlock?: Maybe<CreateRightWrongAssertionBlockPayload>;
  /** Creates a RightWrongDontKnowAnswer. */
  createRightWrongDontKnowAnswer?: Maybe<CreateRightWrongDontKnowAnswerPayload>;
  /** Creates a RightWrongDontKnowBlock. */
  createRightWrongDontKnowBlock?: Maybe<CreateRightWrongDontKnowBlockPayload>;
  /** Creates a ScreenshotBlock. */
  createScreenshotBlock?: Maybe<CreateScreenshotBlockPayload>;
  /** Creates a SequenceAnswer. */
  createSequenceAnswer?: Maybe<CreateSequenceAnswerPayload>;
  /** Creates a SequenceBlock. */
  createSequenceBlock?: Maybe<CreateSequenceBlockPayload>;
  /** Creates a SingleChoiceAnswer. */
  createSingleChoiceAnswer?: Maybe<CreateSingleChoiceAnswerPayload>;
  /** Creates a SingleChoiceBlock. */
  createSingleChoiceBlock?: Maybe<CreateSingleChoiceBlockPayload>;
  /** Creates a TaskBlock. */
  createTaskBlock?: Maybe<CreateTaskBlockPayload>;
  /** Creates a TenantTransfer. */
  createTenantTransfer?: Maybe<CreateTenantTransferPayload>;
  /** Creates a TextInputAnswer. */
  createTextInputAnswer?: Maybe<CreateTextInputAnswerPayload>;
  /** Creates a TextInputBlock. */
  createTextInputBlock?: Maybe<CreateTextInputBlockPayload>;
  /** CreateVariants a Exam. */
  createVariantExam?: Maybe<CreateVariantExamPayload>;
  /** Creates a VmLog. */
  createVmLog?: Maybe<CreateVmLogPayload>;
  /** Deletes a AnomalyLog. */
  deleteAnomalyLog?: Maybe<DeleteAnomalyLogPayload>;
  /** Deletes a Answer. */
  deleteAnswer?: Maybe<DeleteAnswerPayload>;
  /** Deletes a Assignment. */
  deleteAssignment?: Maybe<DeleteAssignmentPayload>;
  /** Deletes a AuthLog. */
  deleteAuthLog?: Maybe<DeleteAuthLogPayload>;
  /** Deletes a Block. */
  deleteBlock?: Maybe<DeleteBlockPayload>;
  /** Deletes a BlockDisplayPosition. */
  deleteBlockDisplayPosition?: Maybe<DeleteBlockDisplayPositionPayload>;
  /** Deletes a BlockLog. */
  deleteBlockLog?: Maybe<DeleteBlockLogPayload>;
  /** Deletes a CodeAreaAnswer. */
  deleteCodeAreaAnswer?: Maybe<DeleteCodeAreaAnswerPayload>;
  /** Deletes a CodeAreaBlock. */
  deleteCodeAreaBlock?: Maybe<DeleteCodeAreaBlockPayload>;
  /** Deletes a CodeBlock. */
  deleteCodeBlock?: Maybe<DeleteCodeBlockPayload>;
  /** Deletes a Content. */
  deleteContent?: Maybe<DeleteContentPayload>;
  /** Deletes a ControlTaskOptions. */
  deleteControlTaskOptions?: Maybe<DeleteControlTaskOptionsPayload>;
  /** Deletes a Correction. */
  deleteCorrection?: Maybe<DeleteCorrectionPayload>;
  /** Deletes a CorrectionApproval. */
  deleteCorrectionApproval?: Maybe<DeleteCorrectionApprovalPayload>;
  /** Deletes a CorrectionLock. */
  deleteCorrectionLock?: Maybe<DeleteCorrectionLockPayload>;
  /** Deletes a DiskTemplate. */
  deleteDiskTemplate?: Maybe<DeleteDiskTemplatePayload>;
  /** Deletes a DownloadRequest. */
  deleteDownloadRequest?: Maybe<DeleteDownloadRequestPayload>;
  /** Deletes a EvaluateLog. */
  deleteEvaluateLog?: Maybe<DeleteEvaluateLogPayload>;
  /** Deletes a Exam. */
  deleteExam?: Maybe<DeleteExamPayload>;
  /** Deletes a ExamActionLog. */
  deleteExamActionLog?: Maybe<DeleteExamActionLogPayload>;
  /** Deletes a ExamEvent. */
  deleteExamEvent?: Maybe<DeleteExamEventPayload>;
  /** Deletes a ExamPass. */
  deleteExamPass?: Maybe<DeleteExamPassPayload>;
  /** Deletes a ExamPingLog. */
  deleteExamPingLog?: Maybe<DeleteExamPingLogPayload>;
  /** Deletes a FileTransferAnswer. */
  deleteFileTransferAnswer?: Maybe<DeleteFileTransferAnswerPayload>;
  /** Deletes a FileTransferBlock. */
  deleteFileTransferBlock?: Maybe<DeleteFileTransferBlockPayload>;
  /** Deletes a FileUploadAnswer. */
  deleteFileUploadAnswer?: Maybe<DeleteFileUploadAnswerPayload>;
  /** Deletes a FileUploadBlock. */
  deleteFileUploadBlock?: Maybe<DeleteFileUploadBlockPayload>;
  /** Deletes a FilesBlock. */
  deleteFilesBlock?: Maybe<DeleteFilesBlockPayload>;
  /** Deletes a H5pBlock. */
  deleteH5pBlock?: Maybe<DeleteH5pBlockPayload>;
  /** Deletes a MatrixChoiceAnswer. */
  deleteMatrixChoiceAnswer?: Maybe<DeleteMatrixChoiceAnswerPayload>;
  /** Deletes a MatrixChoiceBlock. */
  deleteMatrixChoiceBlock?: Maybe<DeleteMatrixChoiceBlockPayload>;
  /** Deletes a MediaObject. */
  deleteMediaObject?: Maybe<DeleteMediaObjectPayload>;
  /** Deletes a MultipleChoiceAnswer. */
  deleteMultipleChoiceAnswer?: Maybe<DeleteMultipleChoiceAnswerPayload>;
  /** Deletes a MultipleChoiceBlock. */
  deleteMultipleChoiceBlock?: Maybe<DeleteMultipleChoiceBlockPayload>;
  /** Deletes a RichTextBlock. */
  deleteRichTextBlock?: Maybe<DeleteRichTextBlockPayload>;
  /** Deletes a RightWrongAssertionAnswer. */
  deleteRightWrongAssertionAnswer?: Maybe<DeleteRightWrongAssertionAnswerPayload>;
  /** Deletes a RightWrongAssertionBlock. */
  deleteRightWrongAssertionBlock?: Maybe<DeleteRightWrongAssertionBlockPayload>;
  /** Deletes a RightWrongDontKnowAnswer. */
  deleteRightWrongDontKnowAnswer?: Maybe<DeleteRightWrongDontKnowAnswerPayload>;
  /** Deletes a RightWrongDontKnowBlock. */
  deleteRightWrongDontKnowBlock?: Maybe<DeleteRightWrongDontKnowBlockPayload>;
  /** Deletes a ScreenshotBlock. */
  deleteScreenshotBlock?: Maybe<DeleteScreenshotBlockPayload>;
  /** Deletes a SequenceAnswer. */
  deleteSequenceAnswer?: Maybe<DeleteSequenceAnswerPayload>;
  /** Deletes a SequenceBlock. */
  deleteSequenceBlock?: Maybe<DeleteSequenceBlockPayload>;
  /** Deletes a SingleChoiceAnswer. */
  deleteSingleChoiceAnswer?: Maybe<DeleteSingleChoiceAnswerPayload>;
  /** Deletes a SingleChoiceBlock. */
  deleteSingleChoiceBlock?: Maybe<DeleteSingleChoiceBlockPayload>;
  /** Deletes a TaskBlock. */
  deleteTaskBlock?: Maybe<DeleteTaskBlockPayload>;
  /** Deletes a TenantTransfer. */
  deleteTenantTransfer?: Maybe<DeleteTenantTransferPayload>;
  /** Deletes a TextInputAnswer. */
  deleteTextInputAnswer?: Maybe<DeleteTextInputAnswerPayload>;
  /** Deletes a TextInputBlock. */
  deleteTextInputBlock?: Maybe<DeleteTextInputBlockPayload>;
  /** Deletes a VmLog. */
  deleteVmLog?: Maybe<DeleteVmLogPayload>;
  /** ExtendAutoArchives a Exam. */
  extendAutoArchiveExam?: Maybe<ExtendAutoArchiveExamPayload>;
  /** ExtendAutoArchives a ExamEvent. */
  extendAutoArchiveExamEvent?: Maybe<ExtendAutoArchiveExamEventPayload>;
  /** Finishs a ExamPass. */
  finishExamPass?: Maybe<FinishExamPassPayload>;
  /** Interrupts a ExamPass. */
  interruptExamPass?: Maybe<InterruptExamPassPayload>;
  /** Locks a ExamPass. */
  lockExamPass?: Maybe<LockExamPassPayload>;
  /** LockPassess a ExamEvent. */
  lockPassesExamEvent?: Maybe<LockPassesExamEventPayload>;
  /** PasteBlockIntos a Exam. */
  pasteBlockIntoExam?: Maybe<PasteBlockIntoExamPayload>;
  /** Pauses a ExamPass. */
  pauseExamPass?: Maybe<PauseExamPassPayload>;
  /** Pings a ExamPass. */
  pingExamPass?: Maybe<PingExamPassPayload>;
  /** Publishs a Exam. */
  publishExam?: Maybe<PublishExamPayload>;
  /** ReassignEnvironmentTos a ExamPass. */
  reassignEnvironmentToExamPass?: Maybe<ReassignEnvironmentToExamPassPayload>;
  /** RemoveMachineFroms a EnvironmentTemplate. */
  removeMachineFromEnvironmentTemplate?: Maybe<RemoveMachineFromEnvironmentTemplatePayload>;
  /** RemoveUsersFroms a ExamEvent. */
  removeUsersFromExamEvent?: Maybe<RemoveUsersFromExamEventPayload>;
  /** Renames a MachineTemplate. */
  renameMachineTemplate?: Maybe<RenameMachineTemplatePayload>;
  /** Reopens a ExamPass. */
  reopenExamPass?: Maybe<ReopenExamPassPayload>;
  /** Resets a Machine. */
  resetMachine?: Maybe<ResetMachinePayload>;
  /** Resumes a ExamPass. */
  resumeExamPass?: Maybe<ResumeExamPassPayload>;
  /** Reverts a Machine. */
  revertMachine?: Maybe<RevertMachinePayload>;
  /** RevokePublisheds a Exam. */
  revokePublishedExam?: Maybe<RevokePublishedExamPayload>;
  /** StartExamEventVmss a ExamEvent. */
  startExamEventVmsExamEvent?: Maybe<StartExamEventVmsExamEventPayload>;
  /** StopExamEventVmss a ExamEvent. */
  stopExamEventVmsExamEvent?: Maybe<StopExamEventVmsExamEventPayload>;
  /** ToggleEnvironments a Exam. */
  toggleEnvironmentExam?: Maybe<ToggleEnvironmentExamPayload>;
  /** Transfers a FileTransferAnswer. */
  transferFileTransferAnswer?: Maybe<TransferFileTransferAnswerPayload>;
  /** Transitions a Machine. */
  transitionMachine?: Maybe<TransitionMachinePayload>;
  /** Updates a AnomalyLog. */
  updateAnomalyLog?: Maybe<UpdateAnomalyLogPayload>;
  /** Updates a Answer. */
  updateAnswer?: Maybe<UpdateAnswerPayload>;
  /** Updates a Assignment. */
  updateAssignment?: Maybe<UpdateAssignmentPayload>;
  /** Updates a AuthLog. */
  updateAuthLog?: Maybe<UpdateAuthLogPayload>;
  /** Updates a Block. */
  updateBlock?: Maybe<UpdateBlockPayload>;
  /** Updates a BlockDisplayPosition. */
  updateBlockDisplayPosition?: Maybe<UpdateBlockDisplayPositionPayload>;
  /** Updates a BlockLog. */
  updateBlockLog?: Maybe<UpdateBlockLogPayload>;
  /** Updates a CodeAreaAnswer. */
  updateCodeAreaAnswer?: Maybe<UpdateCodeAreaAnswerPayload>;
  /** Updates a CodeAreaBlock. */
  updateCodeAreaBlock?: Maybe<UpdateCodeAreaBlockPayload>;
  /** Updates a CodeBlock. */
  updateCodeBlock?: Maybe<UpdateCodeBlockPayload>;
  /** Updates a Content. */
  updateContent?: Maybe<UpdateContentPayload>;
  /** Updates a ControlTaskOptions. */
  updateControlTaskOptions?: Maybe<UpdateControlTaskOptionsPayload>;
  /** Updates a Correction. */
  updateCorrection?: Maybe<UpdateCorrectionPayload>;
  /** Updates a CorrectionApproval. */
  updateCorrectionApproval?: Maybe<UpdateCorrectionApprovalPayload>;
  /** Updates a CorrectionLock. */
  updateCorrectionLock?: Maybe<UpdateCorrectionLockPayload>;
  /** Updates a DownloadRequest. */
  updateDownloadRequest?: Maybe<UpdateDownloadRequestPayload>;
  /** Updates a Environment. */
  updateEnvironment?: Maybe<UpdateEnvironmentPayload>;
  /** Updates a EvaluateLog. */
  updateEvaluateLog?: Maybe<UpdateEvaluateLogPayload>;
  /** Updates a Exam. */
  updateExam?: Maybe<UpdateExamPayload>;
  /** Updates a ExamActionLog. */
  updateExamActionLog?: Maybe<UpdateExamActionLogPayload>;
  /** Updates a ExamEvent. */
  updateExamEvent?: Maybe<UpdateExamEventPayload>;
  /** Updates a ExamPass. */
  updateExamPass?: Maybe<UpdateExamPassPayload>;
  /** Updates a ExamPingLog. */
  updateExamPingLog?: Maybe<UpdateExamPingLogPayload>;
  /** Updates a FileTransferBlock. */
  updateFileTransferBlock?: Maybe<UpdateFileTransferBlockPayload>;
  /** Updates a FileUploadAnswer. */
  updateFileUploadAnswer?: Maybe<UpdateFileUploadAnswerPayload>;
  /** Updates a FileUploadBlock. */
  updateFileUploadBlock?: Maybe<UpdateFileUploadBlockPayload>;
  /** Updates a FilesBlock. */
  updateFilesBlock?: Maybe<UpdateFilesBlockPayload>;
  /** Updates a H5pBlock. */
  updateH5pBlock?: Maybe<UpdateH5pBlockPayload>;
  /** Updates a Machine. */
  updateMachine?: Maybe<UpdateMachinePayload>;
  /** Updates a MachinePermission. */
  updateMachinePermission?: Maybe<UpdateMachinePermissionPayload>;
  /** Updates a MachineTemplate. */
  updateMachineTemplate?: Maybe<UpdateMachineTemplatePayload>;
  /** Updates a MatrixChoiceAnswer. */
  updateMatrixChoiceAnswer?: Maybe<UpdateMatrixChoiceAnswerPayload>;
  /** Updates a MatrixChoiceBlock. */
  updateMatrixChoiceBlock?: Maybe<UpdateMatrixChoiceBlockPayload>;
  /** Updates a MultipleChoiceAnswer. */
  updateMultipleChoiceAnswer?: Maybe<UpdateMultipleChoiceAnswerPayload>;
  /** Updates a MultipleChoiceBlock. */
  updateMultipleChoiceBlock?: Maybe<UpdateMultipleChoiceBlockPayload>;
  /** Updates a NetworkInterfaceTemplate. */
  updateNetworkInterfaceTemplate?: Maybe<UpdateNetworkInterfaceTemplatePayload>;
  /** Updates a NetworkTemplate. */
  updateNetworkTemplate?: Maybe<UpdateNetworkTemplatePayload>;
  /** Updates a RichTextBlock. */
  updateRichTextBlock?: Maybe<UpdateRichTextBlockPayload>;
  /** Updates a RightWrongAssertionAnswer. */
  updateRightWrongAssertionAnswer?: Maybe<UpdateRightWrongAssertionAnswerPayload>;
  /** Updates a RightWrongAssertionBlock. */
  updateRightWrongAssertionBlock?: Maybe<UpdateRightWrongAssertionBlockPayload>;
  /** Updates a RightWrongDontKnowAnswer. */
  updateRightWrongDontKnowAnswer?: Maybe<UpdateRightWrongDontKnowAnswerPayload>;
  /** Updates a RightWrongDontKnowBlock. */
  updateRightWrongDontKnowBlock?: Maybe<UpdateRightWrongDontKnowBlockPayload>;
  /** Updates a ScreenshotBlock. */
  updateScreenshotBlock?: Maybe<UpdateScreenshotBlockPayload>;
  /** Updates a SequenceAnswer. */
  updateSequenceAnswer?: Maybe<UpdateSequenceAnswerPayload>;
  /** Updates a SequenceBlock. */
  updateSequenceBlock?: Maybe<UpdateSequenceBlockPayload>;
  /** Updates a SingleChoiceAnswer. */
  updateSingleChoiceAnswer?: Maybe<UpdateSingleChoiceAnswerPayload>;
  /** Updates a SingleChoiceBlock. */
  updateSingleChoiceBlock?: Maybe<UpdateSingleChoiceBlockPayload>;
  /** Updates a TaskBlock. */
  updateTaskBlock?: Maybe<UpdateTaskBlockPayload>;
  /** Updates a TenantTransfer. */
  updateTenantTransfer?: Maybe<UpdateTenantTransferPayload>;
  /** Updates a TextInputAnswer. */
  updateTextInputAnswer?: Maybe<UpdateTextInputAnswerPayload>;
  /** Updates a TextInputBlock. */
  updateTextInputBlock?: Maybe<UpdateTextInputBlockPayload>;
  /** UpdateTimeLimits a ExamPass. */
  updateTimeLimitExamPass?: Maybe<UpdateTimeLimitExamPassPayload>;
  /** Updates a User. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a VmLog. */
  updateVmLog?: Maybe<UpdateVmLogPayload>;
  /** UploadFiles a Machine. */
  uploadFileMachine?: Maybe<UploadFileMachinePayload>;
  /** UploadFiless a Machine. */
  uploadFilesMachine?: Maybe<UploadFilesMachinePayload>;
  /** Uploads a MediaObject. */
  uploadMediaObject?: Maybe<UploadMediaObjectPayload>;
};


export type MutationAddAllMachinesToEnvironmentTemplateArgs = {
  input: AddAllMachinesToEnvironmentTemplateInput;
};


export type MutationAddGroupsToExamEventArgs = {
  input: AddGroupsToExamEventInput;
};


export type MutationAddMachineToEnvironmentTemplateArgs = {
  input: AddMachineToEnvironmentTemplateInput;
};


export type MutationAddUsersToExamEventArgs = {
  input: AddUsersToExamEventInput;
};


export type MutationArchiveExamArgs = {
  input: ArchiveExamInput;
};


export type MutationArchiveExamEventArgs = {
  input: ArchiveExamEventInput;
};


export type MutationBeginExamPassArgs = {
  input: BeginExamPassInput;
};


export type MutationCaptureScreenshotAnswerArgs = {
  input: CaptureScreenshotAnswerInput;
};


export type MutationCopyPasteAssignmentArgs = {
  input: CopyPasteAssignmentInput;
};


export type MutationCreateAnomalyLogArgs = {
  input: CreateAnomalyLogInput;
};


export type MutationCreateAssignmentArgs = {
  input: CreateAssignmentInput;
};


export type MutationCreateAuthLogArgs = {
  input: CreateAuthLogInput;
};


export type MutationCreateBlockDisplayPositionArgs = {
  input: CreateBlockDisplayPositionInput;
};


export type MutationCreateBlockLogArgs = {
  input: CreateBlockLogInput;
};


export type MutationCreateCodeAreaAnswerArgs = {
  input: CreateCodeAreaAnswerInput;
};


export type MutationCreateCodeAreaBlockArgs = {
  input: CreateCodeAreaBlockInput;
};


export type MutationCreateCodeBlockArgs = {
  input: CreateCodeBlockInput;
};


export type MutationCreateContentArgs = {
  input: CreateContentInput;
};


export type MutationCreateControlTaskOptionsArgs = {
  input: CreateControlTaskOptionsInput;
};


export type MutationCreateCopyExamArgs = {
  input: CreateCopyExamInput;
};


export type MutationCreateCorrectionArgs = {
  input: CreateCorrectionInput;
};


export type MutationCreateCorrectionApprovalArgs = {
  input: CreateCorrectionApprovalInput;
};


export type MutationCreateCorrectionLockArgs = {
  input: CreateCorrectionLockInput;
};


export type MutationCreateDiskTemplateArgs = {
  input: CreateDiskTemplateInput;
};


export type MutationCreateDownloadRequestArgs = {
  input: CreateDownloadRequestInput;
};


export type MutationCreateEvaluateLogArgs = {
  input: CreateEvaluateLogInput;
};


export type MutationCreateExamArgs = {
  input: CreateExamInput;
};


export type MutationCreateExamActionLogArgs = {
  input: CreateExamActionLogInput;
};


export type MutationCreateExamEventArgs = {
  input: CreateExamEventInput;
};


export type MutationCreateExamPingLogArgs = {
  input: CreateExamPingLogInput;
};


export type MutationCreateFileTransferBlockArgs = {
  input: CreateFileTransferBlockInput;
};


export type MutationCreateFileUploadAnswerArgs = {
  input: CreateFileUploadAnswerInput;
};


export type MutationCreateFileUploadBlockArgs = {
  input: CreateFileUploadBlockInput;
};


export type MutationCreateFilesBlockArgs = {
  input: CreateFilesBlockInput;
};


export type MutationCreateH5pBlockArgs = {
  input: CreateH5pBlockInput;
};


export type MutationCreateMatrixChoiceAnswerArgs = {
  input: CreateMatrixChoiceAnswerInput;
};


export type MutationCreateMatrixChoiceBlockArgs = {
  input: CreateMatrixChoiceBlockInput;
};


export type MutationCreateMultipleChoiceAnswerArgs = {
  input: CreateMultipleChoiceAnswerInput;
};


export type MutationCreateMultipleChoiceBlockArgs = {
  input: CreateMultipleChoiceBlockInput;
};


export type MutationCreateRichTextBlockArgs = {
  input: CreateRichTextBlockInput;
};


export type MutationCreateRightWrongAssertionAnswerArgs = {
  input: CreateRightWrongAssertionAnswerInput;
};


export type MutationCreateRightWrongAssertionBlockArgs = {
  input: CreateRightWrongAssertionBlockInput;
};


export type MutationCreateRightWrongDontKnowAnswerArgs = {
  input: CreateRightWrongDontKnowAnswerInput;
};


export type MutationCreateRightWrongDontKnowBlockArgs = {
  input: CreateRightWrongDontKnowBlockInput;
};


export type MutationCreateScreenshotBlockArgs = {
  input: CreateScreenshotBlockInput;
};


export type MutationCreateSequenceAnswerArgs = {
  input: CreateSequenceAnswerInput;
};


export type MutationCreateSequenceBlockArgs = {
  input: CreateSequenceBlockInput;
};


export type MutationCreateSingleChoiceAnswerArgs = {
  input: CreateSingleChoiceAnswerInput;
};


export type MutationCreateSingleChoiceBlockArgs = {
  input: CreateSingleChoiceBlockInput;
};


export type MutationCreateTaskBlockArgs = {
  input: CreateTaskBlockInput;
};


export type MutationCreateTenantTransferArgs = {
  input: CreateTenantTransferInput;
};


export type MutationCreateTextInputAnswerArgs = {
  input: CreateTextInputAnswerInput;
};


export type MutationCreateTextInputBlockArgs = {
  input: CreateTextInputBlockInput;
};


export type MutationCreateVariantExamArgs = {
  input: CreateVariantExamInput;
};


export type MutationCreateVmLogArgs = {
  input: CreateVmLogInput;
};


export type MutationDeleteAnomalyLogArgs = {
  input: DeleteAnomalyLogInput;
};


export type MutationDeleteAnswerArgs = {
  input: DeleteAnswerInput;
};


export type MutationDeleteAssignmentArgs = {
  input: DeleteAssignmentInput;
};


export type MutationDeleteAuthLogArgs = {
  input: DeleteAuthLogInput;
};


export type MutationDeleteBlockArgs = {
  input: DeleteBlockInput;
};


export type MutationDeleteBlockDisplayPositionArgs = {
  input: DeleteBlockDisplayPositionInput;
};


export type MutationDeleteBlockLogArgs = {
  input: DeleteBlockLogInput;
};


export type MutationDeleteCodeAreaAnswerArgs = {
  input: DeleteCodeAreaAnswerInput;
};


export type MutationDeleteCodeAreaBlockArgs = {
  input: DeleteCodeAreaBlockInput;
};


export type MutationDeleteCodeBlockArgs = {
  input: DeleteCodeBlockInput;
};


export type MutationDeleteContentArgs = {
  input: DeleteContentInput;
};


export type MutationDeleteControlTaskOptionsArgs = {
  input: DeleteControlTaskOptionsInput;
};


export type MutationDeleteCorrectionArgs = {
  input: DeleteCorrectionInput;
};


export type MutationDeleteCorrectionApprovalArgs = {
  input: DeleteCorrectionApprovalInput;
};


export type MutationDeleteCorrectionLockArgs = {
  input: DeleteCorrectionLockInput;
};


export type MutationDeleteDiskTemplateArgs = {
  input: DeleteDiskTemplateInput;
};


export type MutationDeleteDownloadRequestArgs = {
  input: DeleteDownloadRequestInput;
};


export type MutationDeleteEvaluateLogArgs = {
  input: DeleteEvaluateLogInput;
};


export type MutationDeleteExamArgs = {
  input: DeleteExamInput;
};


export type MutationDeleteExamActionLogArgs = {
  input: DeleteExamActionLogInput;
};


export type MutationDeleteExamEventArgs = {
  input: DeleteExamEventInput;
};


export type MutationDeleteExamPassArgs = {
  input: DeleteExamPassInput;
};


export type MutationDeleteExamPingLogArgs = {
  input: DeleteExamPingLogInput;
};


export type MutationDeleteFileTransferAnswerArgs = {
  input: DeleteFileTransferAnswerInput;
};


export type MutationDeleteFileTransferBlockArgs = {
  input: DeleteFileTransferBlockInput;
};


export type MutationDeleteFileUploadAnswerArgs = {
  input: DeleteFileUploadAnswerInput;
};


export type MutationDeleteFileUploadBlockArgs = {
  input: DeleteFileUploadBlockInput;
};


export type MutationDeleteFilesBlockArgs = {
  input: DeleteFilesBlockInput;
};


export type MutationDeleteH5pBlockArgs = {
  input: DeleteH5pBlockInput;
};


export type MutationDeleteMatrixChoiceAnswerArgs = {
  input: DeleteMatrixChoiceAnswerInput;
};


export type MutationDeleteMatrixChoiceBlockArgs = {
  input: DeleteMatrixChoiceBlockInput;
};


export type MutationDeleteMediaObjectArgs = {
  input: DeleteMediaObjectInput;
};


export type MutationDeleteMultipleChoiceAnswerArgs = {
  input: DeleteMultipleChoiceAnswerInput;
};


export type MutationDeleteMultipleChoiceBlockArgs = {
  input: DeleteMultipleChoiceBlockInput;
};


export type MutationDeleteRichTextBlockArgs = {
  input: DeleteRichTextBlockInput;
};


export type MutationDeleteRightWrongAssertionAnswerArgs = {
  input: DeleteRightWrongAssertionAnswerInput;
};


export type MutationDeleteRightWrongAssertionBlockArgs = {
  input: DeleteRightWrongAssertionBlockInput;
};


export type MutationDeleteRightWrongDontKnowAnswerArgs = {
  input: DeleteRightWrongDontKnowAnswerInput;
};


export type MutationDeleteRightWrongDontKnowBlockArgs = {
  input: DeleteRightWrongDontKnowBlockInput;
};


export type MutationDeleteScreenshotBlockArgs = {
  input: DeleteScreenshotBlockInput;
};


export type MutationDeleteSequenceAnswerArgs = {
  input: DeleteSequenceAnswerInput;
};


export type MutationDeleteSequenceBlockArgs = {
  input: DeleteSequenceBlockInput;
};


export type MutationDeleteSingleChoiceAnswerArgs = {
  input: DeleteSingleChoiceAnswerInput;
};


export type MutationDeleteSingleChoiceBlockArgs = {
  input: DeleteSingleChoiceBlockInput;
};


export type MutationDeleteTaskBlockArgs = {
  input: DeleteTaskBlockInput;
};


export type MutationDeleteTenantTransferArgs = {
  input: DeleteTenantTransferInput;
};


export type MutationDeleteTextInputAnswerArgs = {
  input: DeleteTextInputAnswerInput;
};


export type MutationDeleteTextInputBlockArgs = {
  input: DeleteTextInputBlockInput;
};


export type MutationDeleteVmLogArgs = {
  input: DeleteVmLogInput;
};


export type MutationExtendAutoArchiveExamArgs = {
  input: ExtendAutoArchiveExamInput;
};


export type MutationExtendAutoArchiveExamEventArgs = {
  input: ExtendAutoArchiveExamEventInput;
};


export type MutationFinishExamPassArgs = {
  input: FinishExamPassInput;
};


export type MutationInterruptExamPassArgs = {
  input: InterruptExamPassInput;
};


export type MutationLockExamPassArgs = {
  input: LockExamPassInput;
};


export type MutationLockPassesExamEventArgs = {
  input: LockPassesExamEventInput;
};


export type MutationPasteBlockIntoExamArgs = {
  input: PasteBlockIntoExamInput;
};


export type MutationPauseExamPassArgs = {
  input: PauseExamPassInput;
};


export type MutationPingExamPassArgs = {
  input: PingExamPassInput;
};


export type MutationPublishExamArgs = {
  input: PublishExamInput;
};


export type MutationReassignEnvironmentToExamPassArgs = {
  input: ReassignEnvironmentToExamPassInput;
};


export type MutationRemoveMachineFromEnvironmentTemplateArgs = {
  input: RemoveMachineFromEnvironmentTemplateInput;
};


export type MutationRemoveUsersFromExamEventArgs = {
  input: RemoveUsersFromExamEventInput;
};


export type MutationRenameMachineTemplateArgs = {
  input: RenameMachineTemplateInput;
};


export type MutationReopenExamPassArgs = {
  input: ReopenExamPassInput;
};


export type MutationResetMachineArgs = {
  input: ResetMachineInput;
};


export type MutationResumeExamPassArgs = {
  input: ResumeExamPassInput;
};


export type MutationRevertMachineArgs = {
  input: RevertMachineInput;
};


export type MutationRevokePublishedExamArgs = {
  input: RevokePublishedExamInput;
};


export type MutationStartExamEventVmsExamEventArgs = {
  input: StartExamEventVmsExamEventInput;
};


export type MutationStopExamEventVmsExamEventArgs = {
  input: StopExamEventVmsExamEventInput;
};


export type MutationToggleEnvironmentExamArgs = {
  input: ToggleEnvironmentExamInput;
};


export type MutationTransferFileTransferAnswerArgs = {
  input: TransferFileTransferAnswerInput;
};


export type MutationTransitionMachineArgs = {
  input: TransitionMachineInput;
};


export type MutationUpdateAnomalyLogArgs = {
  input: UpdateAnomalyLogInput;
};


export type MutationUpdateAnswerArgs = {
  input: UpdateAnswerInput;
};


export type MutationUpdateAssignmentArgs = {
  input: UpdateAssignmentInput;
};


export type MutationUpdateAuthLogArgs = {
  input: UpdateAuthLogInput;
};


export type MutationUpdateBlockArgs = {
  input: UpdateBlockInput;
};


export type MutationUpdateBlockDisplayPositionArgs = {
  input: UpdateBlockDisplayPositionInput;
};


export type MutationUpdateBlockLogArgs = {
  input: UpdateBlockLogInput;
};


export type MutationUpdateCodeAreaAnswerArgs = {
  input: UpdateCodeAreaAnswerInput;
};


export type MutationUpdateCodeAreaBlockArgs = {
  input: UpdateCodeAreaBlockInput;
};


export type MutationUpdateCodeBlockArgs = {
  input: UpdateCodeBlockInput;
};


export type MutationUpdateContentArgs = {
  input: UpdateContentInput;
};


export type MutationUpdateControlTaskOptionsArgs = {
  input: UpdateControlTaskOptionsInput;
};


export type MutationUpdateCorrectionArgs = {
  input: UpdateCorrectionInput;
};


export type MutationUpdateCorrectionApprovalArgs = {
  input: UpdateCorrectionApprovalInput;
};


export type MutationUpdateCorrectionLockArgs = {
  input: UpdateCorrectionLockInput;
};


export type MutationUpdateDownloadRequestArgs = {
  input: UpdateDownloadRequestInput;
};


export type MutationUpdateEnvironmentArgs = {
  input: UpdateEnvironmentInput;
};


export type MutationUpdateEvaluateLogArgs = {
  input: UpdateEvaluateLogInput;
};


export type MutationUpdateExamArgs = {
  input: UpdateExamInput;
};


export type MutationUpdateExamActionLogArgs = {
  input: UpdateExamActionLogInput;
};


export type MutationUpdateExamEventArgs = {
  input: UpdateExamEventInput;
};


export type MutationUpdateExamPassArgs = {
  input: UpdateExamPassInput;
};


export type MutationUpdateExamPingLogArgs = {
  input: UpdateExamPingLogInput;
};


export type MutationUpdateFileTransferBlockArgs = {
  input: UpdateFileTransferBlockInput;
};


export type MutationUpdateFileUploadAnswerArgs = {
  input: UpdateFileUploadAnswerInput;
};


export type MutationUpdateFileUploadBlockArgs = {
  input: UpdateFileUploadBlockInput;
};


export type MutationUpdateFilesBlockArgs = {
  input: UpdateFilesBlockInput;
};


export type MutationUpdateH5pBlockArgs = {
  input: UpdateH5pBlockInput;
};


export type MutationUpdateMachineArgs = {
  input: UpdateMachineInput;
};


export type MutationUpdateMachinePermissionArgs = {
  input: UpdateMachinePermissionInput;
};


export type MutationUpdateMachineTemplateArgs = {
  input: UpdateMachineTemplateInput;
};


export type MutationUpdateMatrixChoiceAnswerArgs = {
  input: UpdateMatrixChoiceAnswerInput;
};


export type MutationUpdateMatrixChoiceBlockArgs = {
  input: UpdateMatrixChoiceBlockInput;
};


export type MutationUpdateMultipleChoiceAnswerArgs = {
  input: UpdateMultipleChoiceAnswerInput;
};


export type MutationUpdateMultipleChoiceBlockArgs = {
  input: UpdateMultipleChoiceBlockInput;
};


export type MutationUpdateNetworkInterfaceTemplateArgs = {
  input: UpdateNetworkInterfaceTemplateInput;
};


export type MutationUpdateNetworkTemplateArgs = {
  input: UpdateNetworkTemplateInput;
};


export type MutationUpdateRichTextBlockArgs = {
  input: UpdateRichTextBlockInput;
};


export type MutationUpdateRightWrongAssertionAnswerArgs = {
  input: UpdateRightWrongAssertionAnswerInput;
};


export type MutationUpdateRightWrongAssertionBlockArgs = {
  input: UpdateRightWrongAssertionBlockInput;
};


export type MutationUpdateRightWrongDontKnowAnswerArgs = {
  input: UpdateRightWrongDontKnowAnswerInput;
};


export type MutationUpdateRightWrongDontKnowBlockArgs = {
  input: UpdateRightWrongDontKnowBlockInput;
};


export type MutationUpdateScreenshotBlockArgs = {
  input: UpdateScreenshotBlockInput;
};


export type MutationUpdateSequenceAnswerArgs = {
  input: UpdateSequenceAnswerInput;
};


export type MutationUpdateSequenceBlockArgs = {
  input: UpdateSequenceBlockInput;
};


export type MutationUpdateSingleChoiceAnswerArgs = {
  input: UpdateSingleChoiceAnswerInput;
};


export type MutationUpdateSingleChoiceBlockArgs = {
  input: UpdateSingleChoiceBlockInput;
};


export type MutationUpdateTaskBlockArgs = {
  input: UpdateTaskBlockInput;
};


export type MutationUpdateTenantTransferArgs = {
  input: UpdateTenantTransferInput;
};


export type MutationUpdateTextInputAnswerArgs = {
  input: UpdateTextInputAnswerInput;
};


export type MutationUpdateTextInputBlockArgs = {
  input: UpdateTextInputBlockInput;
};


export type MutationUpdateTimeLimitExamPassArgs = {
  input: UpdateTimeLimitExamPassInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateVmLogArgs = {
  input: UpdateVmLogInput;
};


export type MutationUploadFileMachineArgs = {
  input: UploadFileMachineInput;
};


export type MutationUploadFilesMachineArgs = {
  input: UploadFilesMachineInput;
};


export type MutationUploadMediaObjectArgs = {
  input: UploadMediaObjectInput;
};

/**
 * Diese Klasse repräsentiert ein automatisch generiertes Netzwerk.
 * Objekte dieser Klasse werden basierend auf ein Network-Template generiert.
 */
export type Network = Node & {
  __typename: 'Network';
  _id: Scalars['String']['output'];
  environment: Environment;
  id: Scalars['ID']['output'];
  interfaces?: Maybe<Array<Maybe<NetworkInterface>>>;
  state: Scalars['String']['output'];
  template: NetworkTemplate;
  vlan?: Maybe<Scalars['Int']['output']>;
  vsphereName: Scalars['String']['output'];
};

/**
 * Diese Klasse repräsentiert eine Netzwerkschnitstelle
 * Objekte dieser Klasse werden basierend auf ein Networkinterface-Template generiert.
 * Eine Netzwerkschnitstelle verbindet eine VM mit einem Netzwerk
 */
export type NetworkInterface = Node & {
  __typename: 'NetworkInterface';
  _id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  machine: Machine;
  network?: Maybe<Network>;
  template: NetworkInterfaceTemplate;
};

/**
 * Diese Klasse repräsentiert eine Netzwerkschnitstelle für eine VM-Box
 * Es Ordnet einer VM-Box einzelne NetworkInterfaceTemplates zur späteren automatischen generierung von Networkinterfaces zu.
 */
export type NetworkInterfaceMaster = Node & {
  __typename: 'NetworkInterfaceMaster';
  _id: Scalars['String']['output'];
  deviceName: Scalars['String']['output'];
  dhcp: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  key: Scalars['Int']['output'];
  macAddress?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<MachineMaster>;
  name: Scalars['String']['output'];
  templates?: Maybe<Array<Maybe<NetworkInterfaceTemplate>>>;
};

/**
 * Diese Klasse repräsentiert eine Netzwerkschnitstellen-Vorlage
 * Einzelne Netzwerkschnitstellen werden basierend auf diese Klasse generiert
 */
export type NetworkInterfaceTemplate = Node & {
  __typename: 'NetworkInterfaceTemplate';
  _id: Scalars['String']['output'];
  dhcp: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  instances?: Maybe<Array<Maybe<NetworkInterface>>>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  macAddress?: Maybe<Scalars['String']['output']>;
  machine: MachineTemplate;
  master: NetworkInterfaceMaster;
  name: Scalars['String']['output'];
  network?: Maybe<NetworkTemplate>;
  primaryInstance?: Maybe<NetworkInterface>;
};

/**
 * Diese Klasse repräsentiert eine Netzwerk-Vorlage
 * Einzelne Netzwerke werden basierend auf diese Klasse generiert.
 */
export type NetworkTemplate = Node & {
  __typename: 'NetworkTemplate';
  _id: Scalars['String']['output'];
  cidr?: Maybe<Scalars['String']['output']>;
  dns?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  environment: EnvironmentTemplate;
  gateway?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instances?: Maybe<Array<Maybe<Network>>>;
  interfaces?: Maybe<Array<Maybe<NetworkInterfaceTemplate>>>;
  name: Scalars['String']['output'];
  primaryInstance?: Maybe<Network>;
  type: Scalars['String']['output'];
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID']['output'];
};

export type Query = {
  __typename: 'Query';
  allBlocksByExamBlocks?: Maybe<BlockConnection>;
  anomalyLog?: Maybe<AnomalyLog>;
  anomalyLogs?: Maybe<Array<Maybe<AnomalyLog>>>;
  answer?: Maybe<Answer>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  assignment?: Maybe<Assignment>;
  assignments?: Maybe<Array<Maybe<Assignment>>>;
  authLog?: Maybe<AuthLog>;
  authLogs?: Maybe<Array<Maybe<AuthLog>>>;
  authoredCourses?: Maybe<CourseConnection>;
  block?: Maybe<Block>;
  blockDisplayPosition?: Maybe<BlockDisplayPosition>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  blockLog?: Maybe<BlockLog>;
  blockLogs?: Maybe<Array<Maybe<BlockLog>>>;
  blocks?: Maybe<BlockConnection>;
  codeAreaAnswer?: Maybe<CodeAreaAnswer>;
  codeAreaAnswers?: Maybe<Array<Maybe<CodeAreaAnswer>>>;
  codeAreaBlock?: Maybe<CodeAreaBlock>;
  codeAreaBlocks?: Maybe<Array<Maybe<CodeAreaBlock>>>;
  codeBlock?: Maybe<CodeBlock>;
  codeBlocks?: Maybe<Array<Maybe<CodeBlock>>>;
  content?: Maybe<Content>;
  contents?: Maybe<Array<Maybe<Content>>>;
  controlTaskOptions?: Maybe<Array<Maybe<ControlTaskOptions>>>;
  /** This query is designed to retrieve examEvent information with examPasses along with their corresponding answers, corrections, and solutions. */
  correctedAnswersResultExamEvent?: Maybe<ExamEvent>;
  correction?: Maybe<Correction>;
  correctionApproval?: Maybe<CorrectionApproval>;
  correctionApprovals?: Maybe<Array<Maybe<CorrectionApproval>>>;
  correctionLock?: Maybe<CorrectionLock>;
  correctionLocks?: Maybe<Array<Maybe<CorrectionLock>>>;
  corrections?: Maybe<Array<Maybe<Correction>>>;
  course?: Maybe<Course>;
  courses?: Maybe<CourseConnection>;
  /** Get currently logged in user */
  currentUser?: Maybe<User>;
  downloadRequest?: Maybe<DownloadRequest>;
  downloadRequests?: Maybe<Array<Maybe<DownloadRequest>>>;
  evaluateLog?: Maybe<EvaluateLog>;
  evaluateLogs?: Maybe<Array<Maybe<EvaluateLog>>>;
  exam?: Maybe<Exam>;
  examActionLog?: Maybe<ExamActionLog>;
  examActionLogs?: Maybe<Array<Maybe<ExamActionLog>>>;
  examEvent?: Maybe<ExamEvent>;
  examEvents?: Maybe<ExamEventConnection>;
  examPass?: Maybe<ExamPass>;
  examPassLog?: Maybe<ExamPassLog>;
  examPassLogs?: Maybe<ExamPassLogConnection>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  examPingLog?: Maybe<ExamPingLog>;
  examPingLogs?: Maybe<Array<Maybe<ExamPingLog>>>;
  exams?: Maybe<ExamConnection>;
  exportAssignment?: Maybe<Assignment>;
  exportExam?: Maybe<Exam>;
  fileTransferAnswer?: Maybe<FileTransferAnswer>;
  fileTransferBlock?: Maybe<FileTransferBlock>;
  fileTransferBlocks?: Maybe<Array<Maybe<FileTransferBlock>>>;
  fileUploadAnswer?: Maybe<FileUploadAnswer>;
  fileUploadAnswers?: Maybe<Array<Maybe<FileUploadAnswer>>>;
  fileUploadBlock?: Maybe<FileUploadBlock>;
  fileUploadBlocks?: Maybe<Array<Maybe<FileUploadBlock>>>;
  filesBlock?: Maybe<FilesBlock>;
  filesBlocks?: Maybe<Array<Maybe<FilesBlock>>>;
  groups?: Maybe<GroupConnection>;
  h5pBlock?: Maybe<H5pBlock>;
  h5pBlocks?: Maybe<Array<Maybe<H5pBlock>>>;
  /**
   * Diese Klasse repräsentiert eine virtuelle Maschine.
   * Objekte dieser Klasse werden basierend auf einem VmTemplate generiert.
   */
  machine?: Maybe<Machine>;
  /**
   * Diese Klasse repräsentiert ein VM-Image.
   * Ein VM-Image wird später bei der generierung von VM's als Basis benutzt.
   */
  machineMaster?: Maybe<MachineMaster>;
  /**
   * Diese Klasse repräsentiert ein VM-Image.
   * Ein VM-Image wird später bei der generierung von VM's als Basis benutzt.
   */
  machineMasters?: Maybe<Array<Maybe<MachineMaster>>>;
  machinePermissions?: Maybe<Array<Maybe<MachinePermission>>>;
  /**
   * Diese Klasse repräsentiert eine Vm-Vorlage.
   * Einzelne VM's werden basierend auf diese Klasse generiert.
   */
  machineTemplate?: Maybe<MachineTemplate>;
  masterSet?: Maybe<MasterSet>;
  masterSets?: Maybe<Array<Maybe<MasterSet>>>;
  matrixChoiceAnswer?: Maybe<MatrixChoiceAnswer>;
  matrixChoiceAnswers?: Maybe<Array<Maybe<MatrixChoiceAnswer>>>;
  matrixChoiceBlock?: Maybe<MatrixChoiceBlock>;
  matrixChoiceBlocks?: Maybe<Array<Maybe<MatrixChoiceBlock>>>;
  missingRequiredBlocks?: Maybe<Array<Maybe<MissingRequiredBlock>>>;
  multipleChoiceAnswer?: Maybe<MultipleChoiceAnswer>;
  multipleChoiceAnswers?: Maybe<Array<Maybe<MultipleChoiceAnswer>>>;
  multipleChoiceBlock?: Maybe<MultipleChoiceBlock>;
  multipleChoiceBlocks?: Maybe<Array<Maybe<MultipleChoiceBlock>>>;
  myWorkingExamPasses?: Maybe<Array<Maybe<ExamPass>>>;
  /**
   * Diese Klasse repräsentiert eine Netzwerk-Vorlage
   * Einzelne Netzwerke werden basierend auf diese Klasse generiert.
   */
  networkTemplate?: Maybe<NetworkTemplate>;
  node?: Maybe<Node>;
  permittedCorrectingUsers?: Maybe<Array<Maybe<User>>>;
  richTextBlock?: Maybe<RichTextBlock>;
  richTextBlocks?: Maybe<Array<Maybe<RichTextBlock>>>;
  rightWrongAssertionAnswer?: Maybe<RightWrongAssertionAnswer>;
  rightWrongAssertionAnswers?: Maybe<Array<Maybe<RightWrongAssertionAnswer>>>;
  rightWrongAssertionBlock?: Maybe<RightWrongAssertionBlock>;
  rightWrongAssertionBlocks?: Maybe<Array<Maybe<RightWrongAssertionBlock>>>;
  rightWrongAssertionPossibilities?: Maybe<Array<Maybe<RightWrongAssertionPossibility>>>;
  rightWrongDontKnowAnswer?: Maybe<RightWrongDontKnowAnswer>;
  rightWrongDontKnowAnswers?: Maybe<Array<Maybe<RightWrongDontKnowAnswer>>>;
  rightWrongDontKnowBlock?: Maybe<RightWrongDontKnowBlock>;
  rightWrongDontKnowBlocks?: Maybe<Array<Maybe<RightWrongDontKnowBlock>>>;
  screenshotAnswer?: Maybe<ScreenshotAnswer>;
  screenshotBlock?: Maybe<ScreenshotBlock>;
  screenshotBlocks?: Maybe<Array<Maybe<ScreenshotBlock>>>;
  /** Search for users by identifier fields */
  searchUsers?: Maybe<UserConnection>;
  sequenceAnswer?: Maybe<SequenceAnswer>;
  sequenceAnswers?: Maybe<Array<Maybe<SequenceAnswer>>>;
  sequenceBlock?: Maybe<SequenceBlock>;
  sequenceBlocks?: Maybe<Array<Maybe<SequenceBlock>>>;
  singleChoiceAnswer?: Maybe<SingleChoiceAnswer>;
  singleChoiceAnswers?: Maybe<Array<Maybe<SingleChoiceAnswer>>>;
  singleChoiceBlock?: Maybe<SingleChoiceBlock>;
  singleChoiceBlocks?: Maybe<Array<Maybe<SingleChoiceBlock>>>;
  taskBlock?: Maybe<TaskBlock>;
  taskBlocks?: Maybe<Array<Maybe<TaskBlock>>>;
  tenantTransfer?: Maybe<TenantTransfer>;
  tenantTransfers?: Maybe<Array<Maybe<TenantTransfer>>>;
  textInputAnswer?: Maybe<TextInputAnswer>;
  textInputAnswers?: Maybe<Array<Maybe<TextInputAnswer>>>;
  textInputBlock?: Maybe<TextInputBlock>;
  textInputBlocks?: Maybe<Array<Maybe<TextInputBlock>>>;
  unfilledBlocksInTaskBlockBlocks?: Maybe<BlockConnection>;
  user?: Maybe<User>;
  users?: Maybe<UserConnection>;
  vmLog?: Maybe<VmLog>;
  vmLogs?: Maybe<Array<Maybe<VmLog>>>;
};


export type QueryAllBlocksByExamBlocksArgs = {
  id: Scalars['ID']['input'];
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  withSolutions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAnomalyLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnomalyLogsArgs = {
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAuthLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAuthLogsArgs = {
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAuthoredCoursesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlockDisplayPositionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlockLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlockLogsArgs = {
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCodeAreaAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCodeAreaAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCodeAreaBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCodeAreaBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCodeBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCodeBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryContentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCorrectedAnswersResultExamEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCorrectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCorrectionApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCorrectionLockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCorrectionsArgs = {
  answer?: InputMaybe<Scalars['String']['input']>;
  answer_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCourseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCoursesArgs = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  acronym_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDownloadRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEvaluateLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEvaluateLogsArgs = {
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryExamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExamActionLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExamActionLogsArgs = {
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryExamEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExamEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  availableFrom?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableFrom>>>;
  availableUntil?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableUntil>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_email?: InputMaybe<Scalars['String']['input']>;
  createdBy_email_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_course?: InputMaybe<Scalars['String']['input']>;
  examVariant_course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Array<InputMaybe<ExamEventFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamEventFilter_Order>>>;
};


export type QueryExamPassArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExamPassLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExamPassLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryExamPingLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExamPingLogsArgs = {
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryExamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ExamFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamFilter_Order>>>;
  parent?: InputMaybe<Scalars['String']['input']>;
  parent_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryExportAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportExamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileTransferAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileTransferBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileTransferBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFileUploadAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileUploadAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFileUploadBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileUploadBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFilesBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFilesBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Array<InputMaybe<GroupFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryH5pBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryH5pBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMachineArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMachineMasterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMachinePermissionsArgs = {
  machine?: InputMaybe<Scalars['String']['input']>;
  machine_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMachineTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMasterSetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMatrixChoiceAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMatrixChoiceAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMatrixChoiceBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMatrixChoiceBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMultipleChoiceAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMultipleChoiceAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMultipleChoiceBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMultipleChoiceBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMyWorkingExamPassesArgs = {
  course?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isToday?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNetworkTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPermittedCorrectingUsersArgs = {
  examOrExamEvent: Scalars['ID']['input'];
};


export type QueryRichTextBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRichTextBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRightWrongAssertionAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRightWrongAssertionAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRightWrongAssertionBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRightWrongAssertionBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRightWrongDontKnowAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRightWrongDontKnowAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRightWrongDontKnowBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRightWrongDontKnowBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryScreenshotAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScreenshotBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScreenshotBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySearchUsersArgs = {
  query: Scalars['String']['input'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySequenceAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySequenceAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySequenceBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySequenceBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySingleChoiceAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySingleChoiceAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySingleChoiceBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySingleChoiceBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTaskBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTenantTransferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTextInputAnswerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTextInputAnswersArgs = {
  block?: InputMaybe<Scalars['String']['input']>;
  block_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTextInputBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTextInputBlocksArgs = {
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUnfilledBlocksInTaskBlockBlocksArgs = {
  id: Scalars['ID']['input'];
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVmLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVmLogsArgs = {
  examPass?: InputMaybe<Scalars['String']['input']>;
  examPass_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RichTextBlock = Block & Node & {
  __typename: 'RichTextBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  content?: Maybe<Scalars['String']['output']>;
  contentWithEmbeds?: Maybe<Scalars['String']['output']>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<Answer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type RightWrongAssertionAnswer = Answer & Node & {
  __typename: 'RightWrongAssertionAnswer';
  _id: Scalars['Int']['output'];
  block: RightWrongAssertionBlock;
  choice?: Maybe<Scalars['String']['output']>;
  correction?: Maybe<Correction>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type RightWrongAssertionAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RightWrongAssertionBlock = Block & Node & {
  __typename: 'RightWrongAssertionBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assertions?: Maybe<Scalars['Iterable']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isAutoCorrectable?: Maybe<Scalars['Boolean']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  possibilities?: Maybe<Array<Maybe<RightWrongAssertionPossibility>>>;
  randomContent?: Maybe<Scalars['Boolean']['output']>;
  randomizedContentProperties: Scalars['Iterable']['output'];
  solution?: Maybe<RightWrongAssertionAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type RightWrongAssertionPossibility = Node & {
  __typename: 'RightWrongAssertionPossibility';
  _id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
  values: Scalars['Iterable']['output'];
};

export type RightWrongDontKnowAnswer = Answer & Node & {
  __typename: 'RightWrongDontKnowAnswer';
  _id: Scalars['Int']['output'];
  block: RightWrongDontKnowBlock;
  choices?: Maybe<Scalars['Iterable']['output']>;
  correction?: Maybe<Correction>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type RightWrongDontKnowAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RightWrongDontKnowBlock = Block & Node & {
  __typename: 'RightWrongDontKnowBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assertions?: Maybe<Scalars['Iterable']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  dontKnowDisabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isAutoCorrectable?: Maybe<Scalars['Boolean']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  randomContent?: Maybe<Scalars['Boolean']['output']>;
  randomizedContentProperties: Scalars['Iterable']['output'];
  solution?: Maybe<RightWrongDontKnowAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type ScreenshotAnswer = Answer & Node & {
  __typename: 'ScreenshotAnswer';
  _id: Scalars['Int']['output'];
  block: ScreenshotBlock;
  correction?: Maybe<Correction>;
  correctionFiles?: Maybe<Array<Maybe<MediaObject>>>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  latestScreenshot?: Maybe<MediaObject>;
  missingAnswer: Scalars['Boolean']['output'];
  screenshots?: Maybe<Array<Maybe<MediaObject>>>;
  type: Scalars['String']['output'];
};


export type ScreenshotAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ScreenshotBlock = Block & Node & {
  __typename: 'ScreenshotBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  machine?: Maybe<MachineTemplate>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<ScreenshotAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type SequenceAnswer = Answer & Node & {
  __typename: 'SequenceAnswer';
  _id: Scalars['Int']['output'];
  block: SequenceBlock;
  correction?: Maybe<Correction>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  sequence?: Maybe<Scalars['Iterable']['output']>;
  type: Scalars['String']['output'];
};


export type SequenceAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SequenceBlock = Block & Node & {
  __typename: 'SequenceBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isAutoCorrectable?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Scalars['Iterable']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<SequenceAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type SingleChoiceAnswer = Answer & Node & {
  __typename: 'SingleChoiceAnswer';
  _id: Scalars['Int']['output'];
  block: SingleChoiceBlock;
  choice?: Maybe<Scalars['String']['output']>;
  correction?: Maybe<Correction>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type SingleChoiceAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SingleChoiceBlock = Block & Node & {
  __typename: 'SingleChoiceBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  choices?: Maybe<Scalars['Iterable']['output']>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  isAutoCorrectable?: Maybe<Scalars['Boolean']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  randomContent?: Maybe<Scalars['Boolean']['output']>;
  randomizedContentProperties: Scalars['Iterable']['output'];
  solution?: Maybe<SingleChoiceAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type Subscription = {
  __typename: 'Subscription';
  /** Subscribes to the update event of a DownloadRequest. */
  updateDownloadRequestSubscribe?: Maybe<Update_SubscriptionDownloadRequestSubscriptionPayload>;
  /** Subscribes to the update event of a ExamPingLog. */
  updateExamPingLogSubscribe?: Maybe<Update_SubscriptionExamPingLogSubscriptionPayload>;
  /**
   * Diese Klasse repräsentiert eine virtuelle Maschine.
   * Objekte dieser Klasse werden basierend auf einem VmTemplate generiert.
   */
  updateMachineSubscribe?: Maybe<Update_SubscriptionMachineSubscriptionPayload>;
  /** Subscribes to the update event of a TenantTransfer. */
  updateTenantTransferSubscribe?: Maybe<Update_SubscriptionTenantTransferSubscriptionPayload>;
};


export type SubscriptionUpdateDownloadRequestSubscribeArgs = {
  input: Update_SubscriptionDownloadRequestSubscriptionInput;
};


export type SubscriptionUpdateExamPingLogSubscribeArgs = {
  input: Update_SubscriptionExamPingLogSubscriptionInput;
};


export type SubscriptionUpdateMachineSubscribeArgs = {
  input: Update_SubscriptionMachineSubscriptionInput;
};


export type SubscriptionUpdateTenantTransferSubscribeArgs = {
  input: Update_SubscriptionTenantTransferSubscriptionInput;
};

export type TaskBlock = Block & Node & {
  __typename: 'TaskBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  blocks?: Maybe<BlockConnection>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  randomPosition?: Maybe<Scalars['Boolean']['output']>;
  solution?: Maybe<Answer>;
  taskBlock?: Maybe<TaskBlock>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


export type TaskBlockBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userOrder_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TenantTransfer = Node & {
  __typename: 'TenantTransfer';
  _id: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  expiresAt: Scalars['DateTime']['output'];
  finished: Scalars['Boolean']['output'];
  guid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  machine?: Maybe<Machine>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TextInputAnswer = Answer & Node & {
  __typename: 'TextInputAnswer';
  _id: Scalars['Int']['output'];
  block: TextInputBlock;
  content?: Maybe<Scalars['String']['output']>;
  correction?: Maybe<Correction>;
  correctionContent?: Maybe<Scalars['String']['output']>;
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type TextInputAnswerExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TextInputBlock = Block & Node & {
  __typename: 'TextInputBlock';
  _id: Scalars['Int']['output'];
  answerBlockIndex?: Maybe<Scalars['Int']['output']>;
  assignment?: Maybe<Assignment>;
  blockDisplayPositions?: Maybe<Array<Maybe<BlockDisplayPosition>>>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
  countsTowardsProgress?: Maybe<Scalars['Boolean']['output']>;
  fontFamily?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['Int']['output']>;
  initialContent?: Maybe<Scalars['String']['output']>;
  inputType?: Maybe<Scalars['String']['output']>;
  inputWidth?: Maybe<Scalars['String']['output']>;
  isAutoCorrectable?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  maxLength?: Maybe<Scalars['Int']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  solution?: Maybe<TextInputAnswer>;
  taskBlock?: Maybe<TaskBlock>;
  type: Scalars['String']['output'];
};

export type User = Node & {
  __typename: 'User';
  _id: Scalars['String']['output'];
  authoredCourses?: Maybe<CourseConnection>;
  canAccessTeacherArea?: Maybe<Scalars['Boolean']['output']>;
  canImpersonate?: Maybe<Scalars['Boolean']['output']>;
  correctingExamEvents?: Maybe<ExamEventConnection>;
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  examPasses?: Maybe<Array<Maybe<ExamPass>>>;
  favouriteExamEvents?: Maybe<ExamEventConnection>;
  favouriteExamPasses?: Maybe<Array<Maybe<ExamPass>>>;
  favouriteExams?: Maybe<ExamConnection>;
  firstname?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  groups?: Maybe<GroupConnection>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['String']['output']>;
  importSource?: Maybe<Scalars['String']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isImpersonated?: Maybe<Scalars['Boolean']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  ldapPassword: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  originalUser?: Maybe<User>;
  password?: Maybe<Scalars['String']['output']>;
  salt?: Maybe<Scalars['String']['output']>;
  teachedCourses?: Maybe<CourseConnection>;
  timezone?: Maybe<Scalars['String']['output']>;
  userIdentifier: Scalars['String']['output'];
  username: Scalars['String']['output'];
};


export type UserAuthoredCoursesArgs = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  acronym_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserCorrectingExamEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  availableFrom?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableFrom>>>;
  availableUntil?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableUntil>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_email?: InputMaybe<Scalars['String']['input']>;
  createdBy_email_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_course?: InputMaybe<Scalars['String']['input']>;
  examVariant_course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Array<InputMaybe<ExamEventFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamEventFilter_Order>>>;
};


export type UserExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UserFavouriteExamEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  availableFrom?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableFrom>>>;
  availableUntil?: InputMaybe<Array<InputMaybe<ExamEventFilter_AvailableUntil>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_email?: InputMaybe<Scalars['String']['input']>;
  createdBy_email_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_course?: InputMaybe<Scalars['String']['input']>;
  examVariant_course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examVariant_isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Array<InputMaybe<ExamEventFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamEventFilter_Order>>>;
};


export type UserFavouriteExamPassesArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  event_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UserFavouriteExamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  course_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ExamFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExamFilter_Order>>>;
  parent?: InputMaybe<Scalars['String']['input']>;
  parent_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UserGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Array<InputMaybe<GroupFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type UserTeachedCoursesArgs = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  acronym_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Cursor connection for User. */
export type UserConnection = {
  __typename: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: UserPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of User. */
export type UserEdge = {
  __typename: 'UserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<User>;
};

/** Information about the current page. */
export type UserPageInfo = {
  __typename: 'UserPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type VmLog = ExamPassLog & Node & {
  __typename: 'VmLog';
  _id: Scalars['String']['output'];
  action: Scalars['String']['output'];
  context: Scalars['Iterable']['output'];
  dateTime: Scalars['DateTime']['output'];
  examPass?: Maybe<ExamPass>;
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
};

/** AddAllMachinesTos a EnvironmentTemplate. */
export type AddAllMachinesToEnvironmentTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** AddAllMachinesTos a EnvironmentTemplate. */
export type AddAllMachinesToEnvironmentTemplatePayload = {
  __typename: 'addAllMachinesToEnvironmentTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  environmentTemplate?: Maybe<EnvironmentTemplate>;
};

/** AddGroupsTos a ExamEvent. */
export type AddGroupsToExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groups: Array<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** AddGroupsTos a ExamEvent. */
export type AddGroupsToExamEventPayload = {
  __typename: 'addGroupsToExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** AddMachineTos a EnvironmentTemplate. */
export type AddMachineToEnvironmentTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  master: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** AddMachineTos a EnvironmentTemplate. */
export type AddMachineToEnvironmentTemplatePayload = {
  __typename: 'addMachineToEnvironmentTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  environmentTemplate?: Maybe<EnvironmentTemplate>;
};

/** AddUsersTos a ExamEvent. */
export type AddUsersToExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  users: Array<Scalars['String']['input']>;
};

/** AddUsersTos a ExamEvent. */
export type AddUsersToExamEventPayload = {
  __typename: 'addUsersToExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** Archives a ExamEvent. */
export type ArchiveExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Archives a ExamEvent. */
export type ArchiveExamEventPayload = {
  __typename: 'archiveExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** Archives a Exam. */
export type ArchiveExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Archives a Exam. */
export type ArchiveExamPayload = {
  __typename: 'archiveExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Begins a ExamPass. */
export type BeginExamPassInput = {
  browserRendererEngine?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Begins a ExamPass. */
export type BeginExamPassPayload = {
  __typename: 'beginExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Captures a ScreenshotAnswer. */
export type CaptureScreenshotAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Captures a ScreenshotAnswer. */
export type CaptureScreenshotAnswerPayload = {
  __typename: 'captureScreenshotAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  screenshotAnswer?: Maybe<ScreenshotAnswer>;
};

/** CopyPastes a Assignment. */
export type CopyPasteAssignmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The assignmentIri which will be copied. */
  copy: Scalars['ID']['input'];
  /** The assignmentIri where the assignment will be pasted. */
  pastedAt: Scalars['ID']['input'];
};

/** CopyPastes a Assignment. */
export type CopyPasteAssignmentPayload = {
  __typename: 'copyPasteAssignmentPayload';
  assignment?: Maybe<Assignment>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Creates a AnomalyLog. */
export type CreateAnomalyLogInput = {
  action: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: Scalars['Iterable']['input'];
  dateTime: Scalars['DateTime']['input'];
  examPass?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

/** Creates a AnomalyLog. */
export type CreateAnomalyLogPayload = {
  __typename: 'createAnomalyLogPayload';
  anomalyLog?: Maybe<AnomalyLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Creates a Assignment. */
export type CreateAssignmentInput = {
  blocks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  children?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exam: Scalars['String']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  /** We need to keep this for api-platform otherwise errors like this occur: https://whatwedo.sentry.io/issues/5757831904/?project=6004515 */
  title: Scalars['String']['input'];
};

/** Creates a Assignment. */
export type CreateAssignmentPayload = {
  __typename: 'createAssignmentPayload';
  assignment?: Maybe<Assignment>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Creates a AuthLog. */
export type CreateAuthLogInput = {
  action: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: Scalars['Iterable']['input'];
  dateTime: Scalars['DateTime']['input'];
  examPass?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

/** Creates a AuthLog. */
export type CreateAuthLogPayload = {
  __typename: 'createAuthLogPayload';
  authLog?: Maybe<AuthLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Creates a BlockDisplayPosition. */
export type CreateBlockDisplayPositionInput = {
  block: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  user: Scalars['String']['input'];
};

/** Creates a BlockDisplayPosition. */
export type CreateBlockDisplayPositionPayload = {
  __typename: 'createBlockDisplayPositionPayload';
  blockDisplayPosition?: Maybe<BlockDisplayPosition>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Creates a BlockLog. */
export type CreateBlockLogInput = {
  action: Scalars['String']['input'];
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: Scalars['Iterable']['input'];
  dateTime: Scalars['DateTime']['input'];
  examPass?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

/** Creates a BlockLog. */
export type CreateBlockLogPayload = {
  __typename: 'createBlockLogPayload';
  blockLog?: Maybe<BlockLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Creates a CodeAreaAnswer. */
export type CreateCodeAreaAnswerInput = {
  block: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  correctionContent?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a CodeAreaAnswer. */
export type CreateCodeAreaAnswerPayload = {
  __typename: 'createCodeAreaAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeAreaAnswer?: Maybe<CodeAreaAnswer>;
};

/** Creates a CodeAreaBlock. */
export type CreateCodeAreaBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a CodeAreaBlock. */
export type CreateCodeAreaBlockPayload = {
  __typename: 'createCodeAreaBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeAreaBlock?: Maybe<CodeAreaBlock>;
};

/** Creates a CodeBlock. */
export type CreateCodeBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a CodeBlock. */
export type CreateCodeBlockPayload = {
  __typename: 'createCodeBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeBlock?: Maybe<CodeBlock>;
};

/** Creates a Content. */
export type CreateContentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  disabledFeatures?: InputMaybe<Scalars['Int']['input']>;
  filteredParameters?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a Content. */
export type CreateContentPayload = {
  __typename: 'createContentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Content>;
};

/** Creates a ControlTaskOptions. */
export type CreateControlTaskOptionsInput = {
  autoCorrect: Scalars['Boolean']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  showSolution?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Creates a ControlTaskOptions. */
export type CreateControlTaskOptionsPayload = {
  __typename: 'createControlTaskOptionsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
};

/** CreateCopys a Exam. */
export type CreateCopyExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  course: Scalars['ID']['input'];
  examEventType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** CreateCopys a Exam. */
export type CreateCopyExamPayload = {
  __typename: 'createCopyExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Creates a CorrectionApproval. */
export type CreateCorrectionApprovalInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a CorrectionApproval. */
export type CreateCorrectionApprovalPayload = {
  __typename: 'createCorrectionApprovalPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctionApproval?: Maybe<CorrectionApproval>;
};

/** Creates a Correction. */
export type CreateCorrectionInput = {
  answer: Scalars['String']['input'];
  autoCorrected?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctionApprovals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  locks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  note?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a CorrectionLock. */
export type CreateCorrectionLockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a CorrectionLock. */
export type CreateCorrectionLockPayload = {
  __typename: 'createCorrectionLockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctionLock?: Maybe<CorrectionLock>;
};

/** Creates a Correction. */
export type CreateCorrectionPayload = {
  __typename: 'createCorrectionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correction?: Maybe<Correction>;
};

/** Creates a DiskTemplate. */
export type CreateDiskTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  machine: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

/** Creates a DiskTemplate. */
export type CreateDiskTemplatePayload = {
  __typename: 'createDiskTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  diskTemplate?: Maybe<DiskTemplate>;
};

/** Creates a DownloadRequest. */
export type CreateDownloadRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  examEvent: Scalars['String']['input'];
  exportFile?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a DownloadRequest. */
export type CreateDownloadRequestPayload = {
  __typename: 'createDownloadRequestPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  downloadRequest?: Maybe<DownloadRequest>;
};

/** Creates a EvaluateLog. */
export type CreateEvaluateLogInput = {
  action: Scalars['String']['input'];
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: Scalars['Iterable']['input'];
  dateTime: Scalars['DateTime']['input'];
  examPass?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

/** Creates a EvaluateLog. */
export type CreateEvaluateLogPayload = {
  __typename: 'createEvaluateLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  evaluateLog?: Maybe<EvaluateLog>;
};

/** Creates a ExamActionLog. */
export type CreateExamActionLogInput = {
  action: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: Scalars['Iterable']['input'];
  dateTime: Scalars['DateTime']['input'];
  examPass?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

/** Creates a ExamActionLog. */
export type CreateExamActionLogPayload = {
  __typename: 'createExamActionLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examActionLog?: Maybe<ExamActionLog>;
};

/** Creates a ExamEvent. */
export type CreateExamEventInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  archivedBy?: InputMaybe<Scalars['String']['input']>;
  autoArchiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  autoArchiveExtendedUntil?: InputMaybe<Scalars['DateTime']['input']>;
  availableFrom: Scalars['DateTime']['input'];
  availableUntil: Scalars['DateTime']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctingUsers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  examVariant: Scalars['String']['input'];
  isFavourite?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  nextStageExecution?: InputMaybe<Scalars['DateTime']['input']>;
  passes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resultDownloadLink?: InputMaybe<Scalars['String']['input']>;
  returnAlternativeGrade?: InputMaybe<Scalars['String']['input']>;
  returnGradeCalculated?: InputMaybe<Scalars['Boolean']['input']>;
  returnMaxPoints?: InputMaybe<Scalars['String']['input']>;
  returnShowCorrection?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowReachedPoints?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowSolution?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowStudentSolution?: InputMaybe<Scalars['Boolean']['input']>;
  returned?: InputMaybe<Scalars['Boolean']['input']>;
  sebPassword?: InputMaybe<Scalars['String']['input']>;
  sebRequired: Scalars['Boolean']['input'];
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a ExamEvent. */
export type CreateExamEventPayload = {
  __typename: 'createExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** Creates a Exam. */
export type CreateExamInput = {
  assignmentsEnabled: Scalars['Boolean']['input'];
  authorNote?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  course: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  environmentEnabled: Scalars['Boolean']['input'];
  environmentMasterSet?: InputMaybe<Scalars['String']['input']>;
  examEventType?: InputMaybe<ExamEventType>;
  /** @deprecated Use examEventType property and check for "PRACTICE" instead. */
  isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
  teamSize: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a Exam. */
export type CreateExamPayload = {
  __typename: 'createExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Creates a ExamPingLog. */
export type CreateExamPingLogInput = {
  action: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: Scalars['Iterable']['input'];
  dateTime: Scalars['DateTime']['input'];
  examPass?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

/** Creates a ExamPingLog. */
export type CreateExamPingLogPayload = {
  __typename: 'createExamPingLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPingLog?: Maybe<ExamPingLog>;
};

/** Creates a FileTransferBlock. */
export type CreateFileTransferBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  directoryPath?: InputMaybe<Scalars['String']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  machine?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a FileTransferBlock. */
export type CreateFileTransferBlockPayload = {
  __typename: 'createFileTransferBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileTransferBlock?: Maybe<FileTransferBlock>;
};

/** Creates a FileUploadAnswer. */
export type CreateFileUploadAnswerInput = {
  block: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  correctionFiles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a FileUploadAnswer. */
export type CreateFileUploadAnswerPayload = {
  __typename: 'createFileUploadAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileUploadAnswer?: Maybe<FileUploadAnswer>;
};

/** Creates a FileUploadBlock. */
export type CreateFileUploadBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfFiles?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a FileUploadBlock. */
export type CreateFileUploadBlockPayload = {
  __typename: 'createFileUploadBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileUploadBlock?: Maybe<FileUploadBlock>;
};

/** Creates a FilesBlock. */
export type CreateFilesBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a FilesBlock. */
export type CreateFilesBlockPayload = {
  __typename: 'createFilesBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  filesBlock?: Maybe<FilesBlock>;
};

/** Creates a H5pBlock. */
export type CreateH5pBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  h5pContents?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a H5pBlock. */
export type CreateH5pBlockPayload = {
  __typename: 'createH5pBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  h5pBlock?: Maybe<H5pBlock>;
};

/** Creates a MatrixChoiceAnswer. */
export type CreateMatrixChoiceAnswerInput = {
  answers?: InputMaybe<Scalars['Iterable']['input']>;
  block: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a MatrixChoiceAnswer. */
export type CreateMatrixChoiceAnswerPayload = {
  __typename: 'createMatrixChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  matrixChoiceAnswer?: Maybe<MatrixChoiceAnswer>;
};

/** Creates a MatrixChoiceBlock. */
export type CreateMatrixChoiceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  answers?: InputMaybe<Scalars['Iterable']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  questions?: InputMaybe<Scalars['Iterable']['input']>;
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  randomContentAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a MatrixChoiceBlock. */
export type CreateMatrixChoiceBlockPayload = {
  __typename: 'createMatrixChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  matrixChoiceBlock?: Maybe<MatrixChoiceBlock>;
};

/** Creates a MultipleChoiceAnswer. */
export type CreateMultipleChoiceAnswerInput = {
  block: Scalars['String']['input'];
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a MultipleChoiceAnswer. */
export type CreateMultipleChoiceAnswerPayload = {
  __typename: 'createMultipleChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  multipleChoiceAnswer?: Maybe<MultipleChoiceAnswer>;
};

/** Creates a MultipleChoiceBlock. */
export type CreateMultipleChoiceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a MultipleChoiceBlock. */
export type CreateMultipleChoiceBlockPayload = {
  __typename: 'createMultipleChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  multipleChoiceBlock?: Maybe<MultipleChoiceBlock>;
};

/** Creates a RichTextBlock. */
export type CreateRichTextBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  contentWithEmbeds?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a RichTextBlock. */
export type CreateRichTextBlockPayload = {
  __typename: 'createRichTextBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  richTextBlock?: Maybe<RichTextBlock>;
};

/** Creates a RightWrongAssertionAnswer. */
export type CreateRightWrongAssertionAnswerInput = {
  block: Scalars['String']['input'];
  choice?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a RightWrongAssertionAnswer. */
export type CreateRightWrongAssertionAnswerPayload = {
  __typename: 'createRightWrongAssertionAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongAssertionAnswer?: Maybe<RightWrongAssertionAnswer>;
};

/** Creates a RightWrongAssertionBlock. */
export type CreateRightWrongAssertionBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assertions?: InputMaybe<Scalars['Iterable']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a RightWrongAssertionBlock. */
export type CreateRightWrongAssertionBlockPayload = {
  __typename: 'createRightWrongAssertionBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongAssertionBlock?: Maybe<RightWrongAssertionBlock>;
};

/** Creates a RightWrongDontKnowAnswer. */
export type CreateRightWrongDontKnowAnswerInput = {
  block: Scalars['String']['input'];
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a RightWrongDontKnowAnswer. */
export type CreateRightWrongDontKnowAnswerPayload = {
  __typename: 'createRightWrongDontKnowAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongDontKnowAnswer?: Maybe<RightWrongDontKnowAnswer>;
};

/** Creates a RightWrongDontKnowBlock. */
export type CreateRightWrongDontKnowBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assertions?: InputMaybe<Scalars['Iterable']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  dontKnowDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a RightWrongDontKnowBlock. */
export type CreateRightWrongDontKnowBlockPayload = {
  __typename: 'createRightWrongDontKnowBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongDontKnowBlock?: Maybe<RightWrongDontKnowBlock>;
};

/** Creates a ScreenshotBlock. */
export type CreateScreenshotBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  machine?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a ScreenshotBlock. */
export type CreateScreenshotBlockPayload = {
  __typename: 'createScreenshotBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  screenshotBlock?: Maybe<ScreenshotBlock>;
};

/** Creates a SequenceAnswer. */
export type CreateSequenceAnswerInput = {
  block: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<Scalars['Iterable']['input']>;
};

/** Creates a SequenceAnswer. */
export type CreateSequenceAnswerPayload = {
  __typename: 'createSequenceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sequenceAnswer?: Maybe<SequenceAnswer>;
};

/** Creates a SequenceBlock. */
export type CreateSequenceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Scalars['Iterable']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a SequenceBlock. */
export type CreateSequenceBlockPayload = {
  __typename: 'createSequenceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sequenceBlock?: Maybe<SequenceBlock>;
};

/** Creates a SingleChoiceAnswer. */
export type CreateSingleChoiceAnswerInput = {
  block: Scalars['String']['input'];
  choice?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a SingleChoiceAnswer. */
export type CreateSingleChoiceAnswerPayload = {
  __typename: 'createSingleChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  singleChoiceAnswer?: Maybe<SingleChoiceAnswer>;
};

/** Creates a SingleChoiceBlock. */
export type CreateSingleChoiceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a SingleChoiceBlock. */
export type CreateSingleChoiceBlockPayload = {
  __typename: 'createSingleChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  singleChoiceBlock?: Maybe<SingleChoiceBlock>;
};

/** Creates a TaskBlock. */
export type CreateTaskBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  blocks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  randomPosition?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** Creates a TaskBlock. */
export type CreateTaskBlockPayload = {
  __typename: 'createTaskBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taskBlock?: Maybe<TaskBlock>;
};

/** Creates a TenantTransfer. */
export type CreateTenantTransferInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a TenantTransfer. */
export type CreateTenantTransferPayload = {
  __typename: 'createTenantTransferPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tenantTransfer?: Maybe<TenantTransfer>;
};

/** Creates a TextInputAnswer. */
export type CreateTextInputAnswerInput = {
  block: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  correctionContent?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Creates a TextInputAnswer. */
export type CreateTextInputAnswerPayload = {
  __typename: 'createTextInputAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  textInputAnswer?: Maybe<TextInputAnswer>;
};

/** Creates a TextInputBlock. */
export type CreateTextInputBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  importId?: InputMaybe<Scalars['Int']['input']>;
  initialContent?: InputMaybe<Scalars['String']['input']>;
  inputType?: InputMaybe<Scalars['String']['input']>;
  inputWidth?: InputMaybe<Scalars['String']['input']>;
  isAutoCorrectable?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  maxLength?: InputMaybe<Scalars['Int']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a TextInputBlock. */
export type CreateTextInputBlockPayload = {
  __typename: 'createTextInputBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  textInputBlock?: Maybe<TextInputBlock>;
};

/** CreateVariants a Exam. */
export type CreateVariantExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** CreateVariants a Exam. */
export type CreateVariantExamPayload = {
  __typename: 'createVariantExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Creates a VmLog. */
export type CreateVmLogInput = {
  action: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: Scalars['Iterable']['input'];
  dateTime: Scalars['DateTime']['input'];
  examPass?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

/** Creates a VmLog. */
export type CreateVmLogPayload = {
  __typename: 'createVmLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  vmLog?: Maybe<VmLog>;
};

/** Deletes a AnomalyLog. */
export type DeleteAnomalyLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a AnomalyLog. */
export type DeleteAnomalyLogPayload = {
  __typename: 'deleteAnomalyLogPayload';
  anomalyLog?: Maybe<AnomalyLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Deletes a Answer. */
export type DeleteAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a Answer. */
export type DeleteAnswerPayload = {
  __typename: 'deleteAnswerPayload';
  answer?: Maybe<Answer>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Deletes a Assignment. */
export type DeleteAssignmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a Assignment. */
export type DeleteAssignmentPayload = {
  __typename: 'deleteAssignmentPayload';
  assignment?: Maybe<Assignment>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Deletes a AuthLog. */
export type DeleteAuthLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a AuthLog. */
export type DeleteAuthLogPayload = {
  __typename: 'deleteAuthLogPayload';
  authLog?: Maybe<AuthLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Deletes a BlockDisplayPosition. */
export type DeleteBlockDisplayPositionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a BlockDisplayPosition. */
export type DeleteBlockDisplayPositionPayload = {
  __typename: 'deleteBlockDisplayPositionPayload';
  blockDisplayPosition?: Maybe<BlockDisplayPosition>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Deletes a Block. */
export type DeleteBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a BlockLog. */
export type DeleteBlockLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a BlockLog. */
export type DeleteBlockLogPayload = {
  __typename: 'deleteBlockLogPayload';
  blockLog?: Maybe<BlockLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Deletes a Block. */
export type DeleteBlockPayload = {
  __typename: 'deleteBlockPayload';
  block?: Maybe<Block>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Deletes a CodeAreaAnswer. */
export type DeleteCodeAreaAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a CodeAreaAnswer. */
export type DeleteCodeAreaAnswerPayload = {
  __typename: 'deleteCodeAreaAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeAreaAnswer?: Maybe<CodeAreaAnswer>;
};

/** Deletes a CodeAreaBlock. */
export type DeleteCodeAreaBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a CodeAreaBlock. */
export type DeleteCodeAreaBlockPayload = {
  __typename: 'deleteCodeAreaBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeAreaBlock?: Maybe<CodeAreaBlock>;
};

/** Deletes a CodeBlock. */
export type DeleteCodeBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a CodeBlock. */
export type DeleteCodeBlockPayload = {
  __typename: 'deleteCodeBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeBlock?: Maybe<CodeBlock>;
};

/** Deletes a Content. */
export type DeleteContentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a Content. */
export type DeleteContentPayload = {
  __typename: 'deleteContentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Content>;
};

/** Deletes a ControlTaskOptions. */
export type DeleteControlTaskOptionsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a ControlTaskOptions. */
export type DeleteControlTaskOptionsPayload = {
  __typename: 'deleteControlTaskOptionsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
};

/** Deletes a CorrectionApproval. */
export type DeleteCorrectionApprovalInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a CorrectionApproval. */
export type DeleteCorrectionApprovalPayload = {
  __typename: 'deleteCorrectionApprovalPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctionApproval?: Maybe<CorrectionApproval>;
};

/** Deletes a Correction. */
export type DeleteCorrectionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a CorrectionLock. */
export type DeleteCorrectionLockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a CorrectionLock. */
export type DeleteCorrectionLockPayload = {
  __typename: 'deleteCorrectionLockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctionLock?: Maybe<CorrectionLock>;
};

/** Deletes a Correction. */
export type DeleteCorrectionPayload = {
  __typename: 'deleteCorrectionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correction?: Maybe<Correction>;
};

/** Deletes a DiskTemplate. */
export type DeleteDiskTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a DiskTemplate. */
export type DeleteDiskTemplatePayload = {
  __typename: 'deleteDiskTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  diskTemplate?: Maybe<DiskTemplate>;
};

/** Deletes a DownloadRequest. */
export type DeleteDownloadRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a DownloadRequest. */
export type DeleteDownloadRequestPayload = {
  __typename: 'deleteDownloadRequestPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  downloadRequest?: Maybe<DownloadRequest>;
};

/** Deletes a EvaluateLog. */
export type DeleteEvaluateLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a EvaluateLog. */
export type DeleteEvaluateLogPayload = {
  __typename: 'deleteEvaluateLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  evaluateLog?: Maybe<EvaluateLog>;
};

/** Deletes a ExamActionLog. */
export type DeleteExamActionLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a ExamActionLog. */
export type DeleteExamActionLogPayload = {
  __typename: 'deleteExamActionLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examActionLog?: Maybe<ExamActionLog>;
};

/** Deletes a ExamEvent. */
export type DeleteExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a ExamEvent. */
export type DeleteExamEventPayload = {
  __typename: 'deleteExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** Deletes a Exam. */
export type DeleteExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a ExamPass. */
export type DeleteExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a ExamPass. */
export type DeleteExamPassPayload = {
  __typename: 'deleteExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Deletes a Exam. */
export type DeleteExamPayload = {
  __typename: 'deleteExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Deletes a ExamPingLog. */
export type DeleteExamPingLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a ExamPingLog. */
export type DeleteExamPingLogPayload = {
  __typename: 'deleteExamPingLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPingLog?: Maybe<ExamPingLog>;
};

/** Deletes a FileTransferAnswer. */
export type DeleteFileTransferAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a FileTransferAnswer. */
export type DeleteFileTransferAnswerPayload = {
  __typename: 'deleteFileTransferAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileTransferAnswer?: Maybe<FileTransferAnswer>;
};

/** Deletes a FileTransferBlock. */
export type DeleteFileTransferBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a FileTransferBlock. */
export type DeleteFileTransferBlockPayload = {
  __typename: 'deleteFileTransferBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileTransferBlock?: Maybe<FileTransferBlock>;
};

/** Deletes a FileUploadAnswer. */
export type DeleteFileUploadAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a FileUploadAnswer. */
export type DeleteFileUploadAnswerPayload = {
  __typename: 'deleteFileUploadAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileUploadAnswer?: Maybe<FileUploadAnswer>;
};

/** Deletes a FileUploadBlock. */
export type DeleteFileUploadBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a FileUploadBlock. */
export type DeleteFileUploadBlockPayload = {
  __typename: 'deleteFileUploadBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileUploadBlock?: Maybe<FileUploadBlock>;
};

/** Deletes a FilesBlock. */
export type DeleteFilesBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a FilesBlock. */
export type DeleteFilesBlockPayload = {
  __typename: 'deleteFilesBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  filesBlock?: Maybe<FilesBlock>;
};

/** Deletes a H5pBlock. */
export type DeleteH5pBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a H5pBlock. */
export type DeleteH5pBlockPayload = {
  __typename: 'deleteH5pBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  h5pBlock?: Maybe<H5pBlock>;
};

/** Deletes a MatrixChoiceAnswer. */
export type DeleteMatrixChoiceAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a MatrixChoiceAnswer. */
export type DeleteMatrixChoiceAnswerPayload = {
  __typename: 'deleteMatrixChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  matrixChoiceAnswer?: Maybe<MatrixChoiceAnswer>;
};

/** Deletes a MatrixChoiceBlock. */
export type DeleteMatrixChoiceBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a MatrixChoiceBlock. */
export type DeleteMatrixChoiceBlockPayload = {
  __typename: 'deleteMatrixChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  matrixChoiceBlock?: Maybe<MatrixChoiceBlock>;
};

/** Deletes a MediaObject. */
export type DeleteMediaObjectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a MediaObject. */
export type DeleteMediaObjectPayload = {
  __typename: 'deleteMediaObjectPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mediaObject?: Maybe<MediaObject>;
};

/** Deletes a MultipleChoiceAnswer. */
export type DeleteMultipleChoiceAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a MultipleChoiceAnswer. */
export type DeleteMultipleChoiceAnswerPayload = {
  __typename: 'deleteMultipleChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  multipleChoiceAnswer?: Maybe<MultipleChoiceAnswer>;
};

/** Deletes a MultipleChoiceBlock. */
export type DeleteMultipleChoiceBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a MultipleChoiceBlock. */
export type DeleteMultipleChoiceBlockPayload = {
  __typename: 'deleteMultipleChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  multipleChoiceBlock?: Maybe<MultipleChoiceBlock>;
};

/** Deletes a RichTextBlock. */
export type DeleteRichTextBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a RichTextBlock. */
export type DeleteRichTextBlockPayload = {
  __typename: 'deleteRichTextBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  richTextBlock?: Maybe<RichTextBlock>;
};

/** Deletes a RightWrongAssertionAnswer. */
export type DeleteRightWrongAssertionAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a RightWrongAssertionAnswer. */
export type DeleteRightWrongAssertionAnswerPayload = {
  __typename: 'deleteRightWrongAssertionAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongAssertionAnswer?: Maybe<RightWrongAssertionAnswer>;
};

/** Deletes a RightWrongAssertionBlock. */
export type DeleteRightWrongAssertionBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a RightWrongAssertionBlock. */
export type DeleteRightWrongAssertionBlockPayload = {
  __typename: 'deleteRightWrongAssertionBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongAssertionBlock?: Maybe<RightWrongAssertionBlock>;
};

/** Deletes a RightWrongDontKnowAnswer. */
export type DeleteRightWrongDontKnowAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a RightWrongDontKnowAnswer. */
export type DeleteRightWrongDontKnowAnswerPayload = {
  __typename: 'deleteRightWrongDontKnowAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongDontKnowAnswer?: Maybe<RightWrongDontKnowAnswer>;
};

/** Deletes a RightWrongDontKnowBlock. */
export type DeleteRightWrongDontKnowBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a RightWrongDontKnowBlock. */
export type DeleteRightWrongDontKnowBlockPayload = {
  __typename: 'deleteRightWrongDontKnowBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongDontKnowBlock?: Maybe<RightWrongDontKnowBlock>;
};

/** Deletes a ScreenshotBlock. */
export type DeleteScreenshotBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a ScreenshotBlock. */
export type DeleteScreenshotBlockPayload = {
  __typename: 'deleteScreenshotBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  screenshotBlock?: Maybe<ScreenshotBlock>;
};

/** Deletes a SequenceAnswer. */
export type DeleteSequenceAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a SequenceAnswer. */
export type DeleteSequenceAnswerPayload = {
  __typename: 'deleteSequenceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sequenceAnswer?: Maybe<SequenceAnswer>;
};

/** Deletes a SequenceBlock. */
export type DeleteSequenceBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a SequenceBlock. */
export type DeleteSequenceBlockPayload = {
  __typename: 'deleteSequenceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sequenceBlock?: Maybe<SequenceBlock>;
};

/** Deletes a SingleChoiceAnswer. */
export type DeleteSingleChoiceAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a SingleChoiceAnswer. */
export type DeleteSingleChoiceAnswerPayload = {
  __typename: 'deleteSingleChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  singleChoiceAnswer?: Maybe<SingleChoiceAnswer>;
};

/** Deletes a SingleChoiceBlock. */
export type DeleteSingleChoiceBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a SingleChoiceBlock. */
export type DeleteSingleChoiceBlockPayload = {
  __typename: 'deleteSingleChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  singleChoiceBlock?: Maybe<SingleChoiceBlock>;
};

/** Deletes a TaskBlock. */
export type DeleteTaskBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a TaskBlock. */
export type DeleteTaskBlockPayload = {
  __typename: 'deleteTaskBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taskBlock?: Maybe<TaskBlock>;
};

/** Deletes a TenantTransfer. */
export type DeleteTenantTransferInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a TenantTransfer. */
export type DeleteTenantTransferPayload = {
  __typename: 'deleteTenantTransferPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tenantTransfer?: Maybe<TenantTransfer>;
};

/** Deletes a TextInputAnswer. */
export type DeleteTextInputAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a TextInputAnswer. */
export type DeleteTextInputAnswerPayload = {
  __typename: 'deleteTextInputAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  textInputAnswer?: Maybe<TextInputAnswer>;
};

/** Deletes a TextInputBlock. */
export type DeleteTextInputBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a TextInputBlock. */
export type DeleteTextInputBlockPayload = {
  __typename: 'deleteTextInputBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  textInputBlock?: Maybe<TextInputBlock>;
};

/** Deletes a VmLog. */
export type DeleteVmLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Deletes a VmLog. */
export type DeleteVmLogPayload = {
  __typename: 'deleteVmLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  vmLog?: Maybe<VmLog>;
};

/** ExtendAutoArchives a ExamEvent. */
export type ExtendAutoArchiveExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** ExtendAutoArchives a ExamEvent. */
export type ExtendAutoArchiveExamEventPayload = {
  __typename: 'extendAutoArchiveExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** ExtendAutoArchives a Exam. */
export type ExtendAutoArchiveExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** ExtendAutoArchives a Exam. */
export type ExtendAutoArchiveExamPayload = {
  __typename: 'extendAutoArchiveExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Finishs a ExamPass. */
export type FinishExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Finishs a ExamPass. */
export type FinishExamPassPayload = {
  __typename: 'finishExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Interrupts a ExamPass. */
export type InterruptExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  interrupted: Scalars['Boolean']['input'];
};

/** Interrupts a ExamPass. */
export type InterruptExamPassPayload = {
  __typename: 'interruptExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Locks a ExamPass. */
export type LockExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locked: Scalars['Boolean']['input'];
};

/** Locks a ExamPass. */
export type LockExamPassPayload = {
  __typename: 'lockExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** LockPassess a ExamEvent. */
export type LockPassesExamEventInput = {
  batchUpdatePasses: Array<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locked: Scalars['Boolean']['input'];
};

/** LockPassess a ExamEvent. */
export type LockPassesExamEventPayload = {
  __typename: 'lockPassesExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** PasteBlockIntos a Exam. */
export type PasteBlockIntoExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Where to paste the sourceBlock. If TaskBlock and Assignment are given, the sourceBlock will be pasted at the TaskBlock. */
  destinationAssignmentIri?: InputMaybe<Scalars['ID']['input']>;
  /** Where to paste the sourceBlock. If TaskBlock and Assignment are given, the sourceBlock will be pasted at the TaskBlock. */
  destinationTaskBlockIri?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
  sourceBlockIri: Scalars['ID']['input'];
};

/** PasteBlockIntos a Exam. */
export type PasteBlockIntoExamPayload = {
  __typename: 'pasteBlockIntoExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Pauses a ExamPass. */
export type PauseExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Pauses a ExamPass. */
export type PauseExamPassPayload = {
  __typename: 'pauseExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Pings a ExamPass. */
export type PingExamPassInput = {
  browserRendererEngine?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Pings a ExamPass. */
export type PingExamPassPayload = {
  __typename: 'pingExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Publishs a Exam. */
export type PublishExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Publishs a Exam. */
export type PublishExamPayload = {
  __typename: 'publishExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** ReassignEnvironmentTos a ExamPass. */
export type ReassignEnvironmentToExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** ReassignEnvironmentTos a ExamPass. */
export type ReassignEnvironmentToExamPassPayload = {
  __typename: 'reassignEnvironmentToExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** RemoveMachineFroms a EnvironmentTemplate. */
export type RemoveMachineFromEnvironmentTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  machine: Scalars['ID']['input'];
};

/** RemoveMachineFroms a EnvironmentTemplate. */
export type RemoveMachineFromEnvironmentTemplatePayload = {
  __typename: 'removeMachineFromEnvironmentTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  environmentTemplate?: Maybe<EnvironmentTemplate>;
};

/** RemoveUsersFroms a ExamEvent. */
export type RemoveUsersFromExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  users: Array<Scalars['String']['input']>;
};

/** RemoveUsersFroms a ExamEvent. */
export type RemoveUsersFromExamEventPayload = {
  __typename: 'removeUsersFromExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** Renames a MachineTemplate. */
export type RenameMachineTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Renames a MachineTemplate. */
export type RenameMachineTemplatePayload = {
  __typename: 'renameMachineTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machineTemplate?: Maybe<MachineTemplate>;
};

/** Reopens a ExamPass. */
export type ReopenExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Reopens a ExamPass. */
export type ReopenExamPassPayload = {
  __typename: 'reopenExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Resets a Machine. */
export type ResetMachineInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Resets a Machine. */
export type ResetMachinePayload = {
  __typename: 'resetMachinePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<Machine>;
};

/** Resumes a ExamPass. */
export type ResumeExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Resumes a ExamPass. */
export type ResumeExamPassPayload = {
  __typename: 'resumeExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Reverts a Machine. */
export type RevertMachineInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Reverts a Machine. */
export type RevertMachinePayload = {
  __typename: 'revertMachinePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<Machine>;
};

/** RevokePublisheds a Exam. */
export type RevokePublishedExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** RevokePublisheds a Exam. */
export type RevokePublishedExamPayload = {
  __typename: 'revokePublishedExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** StartExamEventVmss a ExamEvent. */
export type StartExamEventVmsExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** StartExamEventVmss a ExamEvent. */
export type StartExamEventVmsExamEventPayload = {
  __typename: 'startExamEventVmsExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** StopExamEventVmss a ExamEvent. */
export type StopExamEventVmsExamEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** StopExamEventVmss a ExamEvent. */
export type StopExamEventVmsExamEventPayload = {
  __typename: 'stopExamEventVmsExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** ToggleEnvironments a Exam. */
export type ToggleEnvironmentExamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

/** ToggleEnvironments a Exam. */
export type ToggleEnvironmentExamPayload = {
  __typename: 'toggleEnvironmentExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Transfers a FileTransferAnswer. */
export type TransferFileTransferAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastModified: Scalars['DateTime']['input'];
};

/** Transfers a FileTransferAnswer. */
export type TransferFileTransferAnswerPayload = {
  __typename: 'transferFileTransferAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileTransferAnswer?: Maybe<FileTransferAnswer>;
};

/** Transitions a Machine. */
export type TransitionMachineInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

/** Transitions a Machine. */
export type TransitionMachinePayload = {
  __typename: 'transitionMachinePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<Machine>;
};

/** Updates a AnomalyLog. */
export type UpdateAnomalyLogInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['Iterable']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a AnomalyLog. */
export type UpdateAnomalyLogPayload = {
  __typename: 'updateAnomalyLogPayload';
  anomalyLog?: Maybe<AnomalyLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Updates a Answer. */
export type UpdateAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a Answer. */
export type UpdateAnswerPayload = {
  __typename: 'updateAnswerPayload';
  answer?: Maybe<Answer>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Updates a Assignment. */
export type UpdateAssignmentInput = {
  blocks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  children?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exam?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** We need to keep this for api-platform otherwise errors like this occur: https://whatwedo.sentry.io/issues/5757831904/?project=6004515 */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a Assignment. */
export type UpdateAssignmentPayload = {
  __typename: 'updateAssignmentPayload';
  assignment?: Maybe<Assignment>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Updates a AuthLog. */
export type UpdateAuthLogInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['Iterable']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a AuthLog. */
export type UpdateAuthLogPayload = {
  __typename: 'updateAuthLogPayload';
  authLog?: Maybe<AuthLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Updates a BlockDisplayPosition. */
export type UpdateBlockDisplayPositionInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a BlockDisplayPosition. */
export type UpdateBlockDisplayPositionPayload = {
  __typename: 'updateBlockDisplayPositionPayload';
  blockDisplayPosition?: Maybe<BlockDisplayPosition>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Updates a Block. */
export type UpdateBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a BlockLog. */
export type UpdateBlockLogInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['Iterable']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a BlockLog. */
export type UpdateBlockLogPayload = {
  __typename: 'updateBlockLogPayload';
  blockLog?: Maybe<BlockLog>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Updates a Block. */
export type UpdateBlockPayload = {
  __typename: 'updateBlockPayload';
  block?: Maybe<Block>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Updates a CodeAreaAnswer. */
export type UpdateCodeAreaAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  correctionContent?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a CodeAreaAnswer. */
export type UpdateCodeAreaAnswerPayload = {
  __typename: 'updateCodeAreaAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeAreaAnswer?: Maybe<CodeAreaAnswer>;
};

/** Updates a CodeAreaBlock. */
export type UpdateCodeAreaBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a CodeAreaBlock. */
export type UpdateCodeAreaBlockPayload = {
  __typename: 'updateCodeAreaBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeAreaBlock?: Maybe<CodeAreaBlock>;
};

/** Updates a CodeBlock. */
export type UpdateCodeBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a CodeBlock. */
export type UpdateCodeBlockPayload = {
  __typename: 'updateCodeBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codeBlock?: Maybe<CodeBlock>;
};

/** Updates a Content. */
export type UpdateContentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  disabledFeatures?: InputMaybe<Scalars['Int']['input']>;
  filteredParameters?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  parameters?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a Content. */
export type UpdateContentPayload = {
  __typename: 'updateContentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Content>;
};

/** Updates a ControlTaskOptions. */
export type UpdateControlTaskOptionsInput = {
  autoCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  showSolution?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Updates a ControlTaskOptions. */
export type UpdateControlTaskOptionsPayload = {
  __typename: 'updateControlTaskOptionsPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  controlTaskOptions?: Maybe<ControlTaskOptions>;
};

/** Updates a CorrectionApproval. */
export type UpdateCorrectionApprovalInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a CorrectionApproval. */
export type UpdateCorrectionApprovalPayload = {
  __typename: 'updateCorrectionApprovalPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctionApproval?: Maybe<CorrectionApproval>;
};

/** Updates a Correction. */
export type UpdateCorrectionInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  autoCorrected?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctionApprovals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  note?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a CorrectionLock. */
export type UpdateCorrectionLockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a CorrectionLock. */
export type UpdateCorrectionLockPayload = {
  __typename: 'updateCorrectionLockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctionLock?: Maybe<CorrectionLock>;
};

/** Updates a Correction. */
export type UpdateCorrectionPayload = {
  __typename: 'updateCorrectionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correction?: Maybe<Correction>;
};

/** Updates a DownloadRequest. */
export type UpdateDownloadRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  examEvent?: InputMaybe<Scalars['String']['input']>;
  exportFile?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a DownloadRequest. */
export type UpdateDownloadRequestPayload = {
  __typename: 'updateDownloadRequestPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  downloadRequest?: Maybe<DownloadRequest>;
};

/** Updates a Environment. */
export type UpdateEnvironmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctionMode?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

/** Updates a Environment. */
export type UpdateEnvironmentPayload = {
  __typename: 'updateEnvironmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  environment?: Maybe<UpdateEnvironmentPayloadData>;
};

/** Updates a Environment. */
export type UpdateEnvironmentPayloadData = Node & {
  __typename: 'updateEnvironmentPayloadData';
  correctionMode?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

/** Updates a EvaluateLog. */
export type UpdateEvaluateLogInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['Iterable']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a EvaluateLog. */
export type UpdateEvaluateLogPayload = {
  __typename: 'updateEvaluateLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  evaluateLog?: Maybe<EvaluateLog>;
};

/** Updates a ExamActionLog. */
export type UpdateExamActionLogInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['Iterable']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a ExamActionLog. */
export type UpdateExamActionLogPayload = {
  __typename: 'updateExamActionLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examActionLog?: Maybe<ExamActionLog>;
};

/** Updates a ExamEvent. */
export type UpdateExamEventInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  archivedBy?: InputMaybe<Scalars['String']['input']>;
  autoArchiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  autoArchiveExtendedUntil?: InputMaybe<Scalars['DateTime']['input']>;
  availableFrom?: InputMaybe<Scalars['DateTime']['input']>;
  availableUntil?: InputMaybe<Scalars['DateTime']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctingUsers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  examVariant?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isFavourite?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextStageExecution?: InputMaybe<Scalars['DateTime']['input']>;
  passes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resultDownloadLink?: InputMaybe<Scalars['String']['input']>;
  returnAlternativeGrade?: InputMaybe<Scalars['String']['input']>;
  returnGradeCalculated?: InputMaybe<Scalars['Boolean']['input']>;
  returnMaxPoints?: InputMaybe<Scalars['String']['input']>;
  returnShowCorrection?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowReachedPoints?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowSolution?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowStudentSolution?: InputMaybe<Scalars['Boolean']['input']>;
  returned?: InputMaybe<Scalars['Boolean']['input']>;
  sebPassword?: InputMaybe<Scalars['String']['input']>;
  sebRequired?: InputMaybe<Scalars['Boolean']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a ExamEvent. */
export type UpdateExamEventPayload = {
  __typename: 'updateExamEventPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examEvent?: Maybe<ExamEvent>;
};

/** Updates a Exam. */
export type UpdateExamInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  archivedBy?: InputMaybe<Scalars['String']['input']>;
  assignments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  assignmentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  authorNote?: InputMaybe<Scalars['String']['input']>;
  autoArchiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  autoArchiveExtendedUntil?: InputMaybe<Scalars['DateTime']['input']>;
  canCreateCopy?: InputMaybe<Scalars['Boolean']['input']>;
  canCreateVariant?: InputMaybe<Scalars['Boolean']['input']>;
  canDuplicateVariant?: InputMaybe<Scalars['Boolean']['input']>;
  canPublish?: InputMaybe<Scalars['Boolean']['input']>;
  canRevokePublished?: InputMaybe<Scalars['Boolean']['input']>;
  canUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  candidateRevertEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clipboardEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duplicatedFrom?: InputMaybe<Scalars['String']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  environmentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  environmentMasterSet?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examEventType?: InputMaybe<ExamEventType>;
  export?: InputMaybe<Scalars['Iterable']['input']>;
  hashId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
  isFavourite?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Use examEventType property and check for "PRACTICE" instead. */
  isLongRunning?: InputMaybe<Scalars['Boolean']['input']>;
  mainImage?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  remoteConsoleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  substitute?: InputMaybe<Scalars['Iterable']['input']>;
  substitutes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** TODO: unique=true after migrations are done, and maybe even switch id field completely */
  ulid?: InputMaybe<Scalars['Ulid']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  usbDevicesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  variants?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  webConsoleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Updates a ExamPass. */
export type UpdateExamPassInput = {
  absent?: InputMaybe<Scalars['Boolean']['input']>;
  answers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookmark?: InputMaybe<Scalars['String']['input']>;
  canPause?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  interrupted?: InputMaybe<Scalars['Boolean']['input']>;
  interruptedAt?: InputMaybe<Scalars['DateTime']['input']>;
  interruptedTime?: InputMaybe<Scalars['Int']['input']>;
  isFavourite?: InputMaybe<Scalars['Boolean']['input']>;
  lastActiveAt?: InputMaybe<Scalars['DateTime']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  moodleAssignmentId?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  pausedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pausedTime?: InputMaybe<Scalars['Int']['input']>;
  returnShowCorrection?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowReachedPoints?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowSolution?: InputMaybe<Scalars['Boolean']['input']>;
  returnShowStudentSolution?: InputMaybe<Scalars['Boolean']['input']>;
  returned?: InputMaybe<Scalars['Boolean']['input']>;
  sebConfigKey?: InputMaybe<Scalars['String']['input']>;
  sebLink?: InputMaybe<Scalars['String']['input']>;
  sebRequired?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  teamId?: InputMaybe<Scalars['Int']['input']>;
  teamIndex?: InputMaybe<Scalars['Int']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a ExamPass. */
export type UpdateExamPassPayload = {
  __typename: 'updateExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Updates a Exam. */
export type UpdateExamPayload = {
  __typename: 'updateExamPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
};

/** Updates a ExamPingLog. */
export type UpdateExamPingLogInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['Iterable']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a ExamPingLog. */
export type UpdateExamPingLogPayload = {
  __typename: 'updateExamPingLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPingLog?: Maybe<ExamPingLog>;
};

/** Updates a FileTransferBlock. */
export type UpdateFileTransferBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  directoryPath?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  machine?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a FileTransferBlock. */
export type UpdateFileTransferBlockPayload = {
  __typename: 'updateFileTransferBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileTransferBlock?: Maybe<FileTransferBlock>;
};

/** Updates a FileUploadAnswer. */
export type UpdateFileUploadAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  correctionFiles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a FileUploadAnswer. */
export type UpdateFileUploadAnswerPayload = {
  __typename: 'updateFileUploadAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileUploadAnswer?: Maybe<FileUploadAnswer>;
};

/** Updates a FileUploadBlock. */
export type UpdateFileUploadBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfFiles?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a FileUploadBlock. */
export type UpdateFileUploadBlockPayload = {
  __typename: 'updateFileUploadBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fileUploadBlock?: Maybe<FileUploadBlock>;
};

/** Updates a FilesBlock. */
export type UpdateFilesBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a FilesBlock. */
export type UpdateFilesBlockPayload = {
  __typename: 'updateFilesBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  filesBlock?: Maybe<FilesBlock>;
};

/** Updates a H5pBlock. */
export type UpdateH5pBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  h5pContents?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a H5pBlock. */
export type UpdateH5pBlockPayload = {
  __typename: 'updateH5pBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  h5pBlock?: Maybe<H5pBlock>;
};

/** Updates a Machine. */
export type UpdateMachineInput = {
  canConsole?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  datastore?: InputMaybe<Scalars['String']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  interfaces?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rdpLink?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  tenantTransfers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transferNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
  vmrcLink?: InputMaybe<Scalars['String']['input']>;
  vmxPath?: InputMaybe<Scalars['String']['input']>;
  vsphereName?: InputMaybe<Scalars['String']['input']>;
  webmksLink?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a Machine. */
export type UpdateMachinePayload = {
  __typename: 'updateMachinePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<Machine>;
};

/** Updates a MachinePermission. */
export type UpdateMachinePermissionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  machine?: InputMaybe<Scalars['String']['input']>;
  participantIndex?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a MachinePermission. */
export type UpdateMachinePermissionPayload = {
  __typename: 'updateMachinePermissionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machinePermission?: Maybe<MachinePermission>;
};

/** Updates a MachineTemplate. */
export type UpdateMachineTemplateInput = {
  autoBoot?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cpu?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  primaryInterface?: InputMaybe<Scalars['String']['input']>;
  ram?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a MachineTemplate. */
export type UpdateMachineTemplatePayload = {
  __typename: 'updateMachineTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machineTemplate?: Maybe<MachineTemplate>;
};

/** Updates a MatrixChoiceAnswer. */
export type UpdateMatrixChoiceAnswerInput = {
  answers?: InputMaybe<Scalars['Iterable']['input']>;
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a MatrixChoiceAnswer. */
export type UpdateMatrixChoiceAnswerPayload = {
  __typename: 'updateMatrixChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  matrixChoiceAnswer?: Maybe<MatrixChoiceAnswer>;
};

/** Updates a MatrixChoiceBlock. */
export type UpdateMatrixChoiceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  answers?: InputMaybe<Scalars['Iterable']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<Scalars['Iterable']['input']>;
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  randomContentAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a MatrixChoiceBlock. */
export type UpdateMatrixChoiceBlockPayload = {
  __typename: 'updateMatrixChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  matrixChoiceBlock?: Maybe<MatrixChoiceBlock>;
};

/** Updates a MultipleChoiceAnswer. */
export type UpdateMultipleChoiceAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a MultipleChoiceAnswer. */
export type UpdateMultipleChoiceAnswerPayload = {
  __typename: 'updateMultipleChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  multipleChoiceAnswer?: Maybe<MultipleChoiceAnswer>;
};

/** Updates a MultipleChoiceBlock. */
export type UpdateMultipleChoiceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a MultipleChoiceBlock. */
export type UpdateMultipleChoiceBlockPayload = {
  __typename: 'updateMultipleChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  multipleChoiceBlock?: Maybe<MultipleChoiceBlock>;
};

/** Updates a NetworkInterfaceTemplate. */
export type UpdateNetworkInterfaceTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dhcp?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  instances?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  machine?: InputMaybe<Scalars['String']['input']>;
  master?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<Scalars['String']['input']>;
  primaryInstance?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a NetworkInterfaceTemplate. */
export type UpdateNetworkInterfaceTemplatePayload = {
  __typename: 'updateNetworkInterfaceTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  networkInterfaceTemplate?: Maybe<NetworkInterfaceTemplate>;
};

/** Updates a NetworkTemplate. */
export type UpdateNetworkTemplateInput = {
  cidr?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dns?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  gateway?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instances?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  interfaces?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a NetworkTemplate. */
export type UpdateNetworkTemplatePayload = {
  __typename: 'updateNetworkTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  networkTemplate?: Maybe<NetworkTemplate>;
};

/** Updates a RichTextBlock. */
export type UpdateRichTextBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  contentWithEmbeds?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a RichTextBlock. */
export type UpdateRichTextBlockPayload = {
  __typename: 'updateRichTextBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  richTextBlock?: Maybe<RichTextBlock>;
};

/** Updates a RightWrongAssertionAnswer. */
export type UpdateRightWrongAssertionAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  choice?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a RightWrongAssertionAnswer. */
export type UpdateRightWrongAssertionAnswerPayload = {
  __typename: 'updateRightWrongAssertionAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongAssertionAnswer?: Maybe<RightWrongAssertionAnswer>;
};

/** Updates a RightWrongAssertionBlock. */
export type UpdateRightWrongAssertionBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assertions?: InputMaybe<Scalars['Iterable']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a RightWrongAssertionBlock. */
export type UpdateRightWrongAssertionBlockPayload = {
  __typename: 'updateRightWrongAssertionBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongAssertionBlock?: Maybe<RightWrongAssertionBlock>;
};

/** Updates a RightWrongDontKnowAnswer. */
export type UpdateRightWrongDontKnowAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a RightWrongDontKnowAnswer. */
export type UpdateRightWrongDontKnowAnswerPayload = {
  __typename: 'updateRightWrongDontKnowAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongDontKnowAnswer?: Maybe<RightWrongDontKnowAnswer>;
};

/** Updates a RightWrongDontKnowBlock. */
export type UpdateRightWrongDontKnowBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assertions?: InputMaybe<Scalars['Iterable']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  dontKnowDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a RightWrongDontKnowBlock. */
export type UpdateRightWrongDontKnowBlockPayload = {
  __typename: 'updateRightWrongDontKnowBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rightWrongDontKnowBlock?: Maybe<RightWrongDontKnowBlock>;
};

/** Updates a ScreenshotBlock. */
export type UpdateScreenshotBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  machine?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a ScreenshotBlock. */
export type UpdateScreenshotBlockPayload = {
  __typename: 'updateScreenshotBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  screenshotBlock?: Maybe<ScreenshotBlock>;
};

/** Updates a SequenceAnswer. */
export type UpdateSequenceAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<Scalars['Iterable']['input']>;
};

/** Updates a SequenceAnswer. */
export type UpdateSequenceAnswerPayload = {
  __typename: 'updateSequenceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sequenceAnswer?: Maybe<SequenceAnswer>;
};

/** Updates a SequenceBlock. */
export type UpdateSequenceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Scalars['Iterable']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a SequenceBlock. */
export type UpdateSequenceBlockPayload = {
  __typename: 'updateSequenceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sequenceBlock?: Maybe<SequenceBlock>;
};

/** Updates a SingleChoiceAnswer. */
export type UpdateSingleChoiceAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  choice?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a SingleChoiceAnswer. */
export type UpdateSingleChoiceAnswerPayload = {
  __typename: 'updateSingleChoiceAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  singleChoiceAnswer?: Maybe<SingleChoiceAnswer>;
};

/** Updates a SingleChoiceBlock. */
export type UpdateSingleChoiceBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Scalars['Iterable']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  randomContent?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a SingleChoiceBlock. */
export type UpdateSingleChoiceBlockPayload = {
  __typename: 'updateSingleChoiceBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  singleChoiceBlock?: Maybe<SingleChoiceBlock>;
};

/** Updates a TaskBlock. */
export type UpdateTaskBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  blocks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  randomPosition?: InputMaybe<Scalars['Boolean']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a TaskBlock. */
export type UpdateTaskBlockPayload = {
  __typename: 'updateTaskBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taskBlock?: Maybe<TaskBlock>;
};

/** Updates a TenantTransfer. */
export type UpdateTenantTransferInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a TenantTransfer. */
export type UpdateTenantTransferPayload = {
  __typename: 'updateTenantTransferPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tenantTransfer?: Maybe<TenantTransfer>;
};

/** Updates a TextInputAnswer. */
export type UpdateTextInputAnswerInput = {
  block?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<Scalars['String']['input']>;
  correctionContent?: InputMaybe<Scalars['String']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
};

/** Updates a TextInputAnswer. */
export type UpdateTextInputAnswerPayload = {
  __typename: 'updateTextInputAnswerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  textInputAnswer?: Maybe<TextInputAnswer>;
};

/** Updates a TextInputBlock. */
export type UpdateTextInputBlockInput = {
  answerBlockIndex?: InputMaybe<Scalars['Int']['input']>;
  assignment?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controlTaskOptions?: InputMaybe<Scalars['String']['input']>;
  countsTowardsProgress?: InputMaybe<Scalars['Boolean']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['Int']['input']>;
  initialContent?: InputMaybe<Scalars['String']['input']>;
  inputType?: InputMaybe<Scalars['String']['input']>;
  inputWidth?: InputMaybe<Scalars['String']['input']>;
  isAutoCorrectable?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  maxLength?: InputMaybe<Scalars['Int']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  taskBlock?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a TextInputBlock. */
export type UpdateTextInputBlockPayload = {
  __typename: 'updateTextInputBlockPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  textInputBlock?: Maybe<TextInputBlock>;
};

/** UpdateTimeLimits a ExamPass. */
export type UpdateTimeLimitExamPassInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
};

/** UpdateTimeLimits a ExamPass. */
export type UpdateTimeLimitExamPassPayload = {
  __typename: 'updateTimeLimitExamPassPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  examPass?: Maybe<ExamPass>;
};

/** Updates a User. */
export type UpdateUserInput = {
  authoredCourses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  canAccessTeacherArea?: InputMaybe<Scalars['Boolean']['input']>;
  canImpersonate?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctingExamEvents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  examPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  favouriteExamEvents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  favouriteExamPasses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  favouriteExams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  importId?: InputMaybe<Scalars['String']['input']>;
  importSource?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonated?: InputMaybe<Scalars['Boolean']['input']>;
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  ldapPassword?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  originalUser?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  teachedCourses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a User. */
export type UpdateUserPayload = {
  __typename: 'updateUserPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** Updates a VmLog. */
export type UpdateVmLogInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['Iterable']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  examPass?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a VmLog. */
export type UpdateVmLogPayload = {
  __typename: 'updateVmLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  vmLog?: Maybe<VmLog>;
};

/** Subscribes to the update event of a DownloadRequest. */
export type Update_SubscriptionDownloadRequestSubscriptionInput = {
  clientSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Subscribes to the update event of a DownloadRequest. */
export type Update_SubscriptionDownloadRequestSubscriptionPayload = {
  __typename: 'update_subscriptionDownloadRequestSubscriptionPayload';
  clientSubscriptionId?: Maybe<Scalars['String']['output']>;
  downloadRequest?: Maybe<DownloadRequest>;
  mercureUrl?: Maybe<Scalars['String']['output']>;
};

/** Subscribes to the update event of a ExamPingLog. */
export type Update_SubscriptionExamPingLogSubscriptionInput = {
  clientSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Subscribes to the update event of a ExamPingLog. */
export type Update_SubscriptionExamPingLogSubscriptionPayload = {
  __typename: 'update_subscriptionExamPingLogSubscriptionPayload';
  clientSubscriptionId?: Maybe<Scalars['String']['output']>;
  examPingLog?: Maybe<ExamPingLog>;
  mercureUrl?: Maybe<Scalars['String']['output']>;
};

/**
 * Diese Klasse repräsentiert eine virtuelle Maschine.
 * Objekte dieser Klasse werden basierend auf einem VmTemplate generiert.
 */
export type Update_SubscriptionMachineSubscriptionInput = {
  clientSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/**
 * Diese Klasse repräsentiert eine virtuelle Maschine.
 * Objekte dieser Klasse werden basierend auf einem VmTemplate generiert.
 */
export type Update_SubscriptionMachineSubscriptionPayload = {
  __typename: 'update_subscriptionMachineSubscriptionPayload';
  clientSubscriptionId?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<Machine>;
  mercureUrl?: Maybe<Scalars['String']['output']>;
};

/** Subscribes to the update event of a TenantTransfer. */
export type Update_SubscriptionTenantTransferSubscriptionInput = {
  clientSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Subscribes to the update event of a TenantTransfer. */
export type Update_SubscriptionTenantTransferSubscriptionPayload = {
  __typename: 'update_subscriptionTenantTransferSubscriptionPayload';
  clientSubscriptionId?: Maybe<Scalars['String']['output']>;
  mercureUrl?: Maybe<Scalars['String']['output']>;
  tenantTransfer?: Maybe<TenantTransfer>;
};

/** UploadFiles a Machine. */
export type UploadFileMachineInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
};

/** UploadFiles a Machine. */
export type UploadFileMachinePayload = {
  __typename: 'uploadFileMachinePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<Machine>;
};

/** UploadFiless a Machine. */
export type UploadFilesMachineInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
};

/** UploadFiless a Machine. */
export type UploadFilesMachinePayload = {
  __typename: 'uploadFilesMachinePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  machine?: Maybe<Machine>;
};

/** Uploads a MediaObject. */
export type UploadMediaObjectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Id of exam */
  exam?: InputMaybe<Scalars['ID']['input']>;
  /** Upload a file */
  file: Scalars['Upload']['input'];
  /** Id of a file transfer answer */
  fileTransferAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** Id of a file transfer answer */
  fileTransferAnswerCorrection?: InputMaybe<Scalars['ID']['input']>;
  /** Id of a file upload answer */
  fileUploadAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** Id of a file upload answer */
  fileUploadAnswerCorrection?: InputMaybe<Scalars['ID']['input']>;
  /** Id of a files block */
  filesBlock?: InputMaybe<Scalars['ID']['input']>;
  /** Last modified date of the file */
  lastModified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Id of a screenshot answer */
  screenshotAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** Id of a screenshot answer */
  screenshotAnswerCorrection?: InputMaybe<Scalars['ID']['input']>;
};

/** Uploads a MediaObject. */
export type UploadMediaObjectPayload = {
  __typename: 'uploadMediaObjectPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mediaObject?: Maybe<MediaObject>;
};
