import { UserFavouritesCountFragment } from './graphql/UserFavouritesCountFragment.generated';
import { useMemo } from 'react';
import { ExamType } from './types/ExamType';
import { ExamEventType } from '~/types/graphql';

interface UserFavouritesCountProps {
    user: UserFavouritesCountFragment;
    filter?: {
        examType?: ExamType;

        /**
         * Exclude those types
         * If include and exclude are both defined, include takes presence
         */
        exclude?: ('passes' | 'examEvents' | 'exams')[] | null;

        /**
         * Include those types only.
         * If include and exclude are both defined, include takes presence
         */
        include?: ('passes' | 'examEvents' | 'exams')[] | null;
    };
}

/**
 * Hook to make counting favourites easier.
 * This currently takes into considerations limitations of the cache and the API. It also filters long running items locally,
 * which is totally optional but is helping using the urql cache.
 * @returns
 */
export function useUserFavouritesCount({
    user,
    filter,
}: UserFavouritesCountProps) {
    const favouriteExamEvents = user?.favouriteExamEvents;
    const favouriteExamPasses = user?.favouriteExamPasses;
    const favouriteExams = user?.favouriteExams;

    const favouritesCount = useMemo(() => {
        const isLongRunning =
            filter?.examType !== undefined && filter?.examType !== 'ALL'
                ? filter?.examType === ExamEventType.Practice
                : undefined;
        let count = 0;

        const favouriteExamEventsCount = favouriteExamEvents?.totalCount || 0;
        const favouriteExamsCount = favouriteExams?.totalCount || 0;

        // The API for exam passes is slighlty different to the ones for
        // exams and events. It is not paginated and future/past events are not
        // separated per query but on client-side. See pending change request:
        // https://dev.whatwedo.ch/smartlearn/smartlearn/-/issues/2783
        const favouritePassesCount =
            isLongRunning !== undefined
                ? favouriteExamPasses?.filter(
                      (pass) =>
                          pass.event.examVariant.isLongRunning === isLongRunning
                  ).length
                : favouriteExamPasses?.length || 0;

        if (
            filter?.include?.includes('passes') ||
            (!filter?.include && !filter?.exclude?.includes('passes'))
        ) {
            count += favouritePassesCount;
        }

        if (
            filter?.include?.includes('exams') ||
            (!filter?.include && !filter?.exclude?.includes('exams'))
        ) {
            count += favouriteExamsCount;
        }

        if (
            filter?.include?.includes('examEvents') ||
            (!filter?.include && !filter?.exclude?.includes('examEvents'))
        ) {
            count += favouriteExamEventsCount;
        }

        return count;
    }, [
        filter?.examType,
        filter?.include,
        filter?.exclude,
        favouriteExamEvents?.totalCount,
        favouriteExams?.totalCount,
        favouriteExamPasses,
    ]);

    return favouritesCount;
}
