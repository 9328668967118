import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useCurrentUserFavouritesCount } from '~/lib/useCurrentUserFavouritesCount';
import { ExamEventType } from '~/types/graphql';

interface DynamicFavouriteIndexProps {
    /**
     * Page with the favourites for the current context
     */
    favouriteRoute: string;

    /**
     * Fallback route for when there are no favourites
     */
    fallbackRoute: string;
}

/**
 * A page that either loads the given favourite route or falls back
 * to the given fallbackRoute if the user does not have any favourites.
 * Used for dynamic index pages.
 */
export function DynamicFavouriteIndex(props: DynamicFavouriteIndexProps) {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const isLongRunning = searchParams.get('long');
    const queryParam = searchParams.get('q');

    // Always ensure isLongRunning is explicitly null on overview pages
    // to ensure all data is retrieved
    const isOverview = ['/', '/events', '/favourites'].includes(
        location.pathname
    );

    const [favouritesCount, fetching] = useCurrentUserFavouritesCount({
        filter: {
            examType: isLongRunning
                ? ExamEventType.Practice
                : isOverview
                ? 'ALL'
                : ExamEventType.Exam,
        },
    });

    if (fetching) {
        return null;
    }

    // There is a better approach to this Index-Component, by using react-router-dom's `loader` feature. Though, as we use async urlq graphql
    // queries via Hooks, that approach doesn't work without workarounds. Therefore, using a dedicated index component for handling the dynamic
    // routing was the working approach.
    // See https://reactrouter.com/en/6.14.2/route/loader for understanding the alternative approach to this.
    if (!queryParam && favouritesCount > 0) {
        return (
            <Navigate
                to={`${props.favouriteRoute}${location.search}`}
                replace
            />
        );
    } else {
        return (
            <Navigate to={`${props.fallbackRoute}${location.search}`} replace />
        );
    }
}
